import React, { useEffect, useState } from "react";
import { faInfoCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./LiveLog.css"
import { IConstants } from "src/types";
import { faBracketsCurly, faPlay, faStop, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faArrowTurnDownRight } from "@fortawesome/pro-thin-svg-icons"
import { PageHeader } from "../../components/Common"
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { useRec, useWebSocket } from "./WebSocketLiveLog";
import NoDataImage  from '../../components/Common/DataTable/empty-logs.png';
import { Button } from 'react-bootstrap';
import { ObjectInspector as ObjectInspector } from "react-inspector";
import { strings } from "../../services/Localization"
import { dateTimeString } from "../../../src/utils/filters";
import { useNavigate } from "react-router-dom";
import { GenericDassQuery } from "../../services/BasicDassQueries";


declare const constants: IConstants;

const LogLevelIcon = {

  "error": { "icon": faCircleExclamation, "color": "red" },
  "info": { "icon": faInfoCircle, "color": "blue" },
  "verbose": { "icon": faInfoCircle, "color": "green" },
  "debug": { "icon": faInfoCircle, "color": "grey" },
  "code": { "icon": faBracketsCurly, "color": "grey" },

}

interface LiveLogRecord {

  meta: {
    level: "error" | "info" | "verbose" | "debug";
    connectionUuid?: string;
    deviceUuid?: string;
    event?: string;
    lambda?: string;
    stats?: any;
    timestamp: Date;
  };
  log: (string | object)[];

}

const ShowNoDataLiveLog = ( status ) => {
  let data = status == "false" ? strings.LIVELOG_NOT_ACTIVE_LETS_START_RECORDING
                               : strings.LIVELOG_IS_ACTIVE_PLEASE_WAIT;
  return (
      <div className='mb-2' dangerouslySetInnerHTML={{__html: data}}>
      </div>
  )
}

const showButton = (button : PageButtonType) => {
  if (button) {
      return ( <div style={{margin:'1px', float:'left'}}>
                  <Button size='sm' className="text-nowrap" title={button.title} variant="dark" onClick={button.action}>
                      {button.icon &&  <FontAwesomeIcon className="mr-2" icon={button.icon} />}
                      {button.title}
                  </Button>
              </div>)
  } else {
      return null;
  }
}

// const Overlay = ({ rec, title }) => {
//   return (
//     <OverlayTrigger
//       placement="auto"
//       overlay={<Tooltip>{title}</Tooltip>}
//     >
//       {rec}
//     </OverlayTrigger>
//   );
// };

export const openDB = (dbName: string, storeName): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onerror = (event) => {
      reject(`Error opening database: ${event}`);
    };

    request.onsuccess = (event) => {
      const db = (event.target as IDBRequest<IDBDatabase>).result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBRequest<IDBDatabase>).result;
      db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true });
    };
  });
};

export const readDataFromDB = (db: IDBDatabase, storeName: string ): Promise<LiveLogRecord[]> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readonly');
    const objectStore = transaction.objectStore(storeName);

    const request = objectStore.getAll();

    request.onsuccess = (event) => {
      const data = (event.target as IDBRequest<IDBDatabase>).result;
      resolve(data as any);
    };

    request.onerror = (event) => {
      reject(`Error reading data from database: ${event}`);
    };
  });
};

export const addDataToDB = (db: IDBDatabase, storeName: string, newLog: LiveLogRecord): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, 'readwrite');
    const objectStore = transaction.objectStore(storeName);

    // Check the total number of entries
    const countRequest = objectStore.count();

    countRequest.onsuccess = async () => {
      const count = countRequest.result;

      try {
        const limit = 1500;
        if (count >= limit) {
          const keysToDelete = await new Promise<number[]>((keysResolve, keysReject) => {
            const getAllKeysRequest = objectStore.getAllKeys(undefined, 100);
            getAllKeysRequest.onsuccess = () => keysResolve((getAllKeysRequest as IDBRequest).result);
            getAllKeysRequest.onerror = (event) => keysReject(`Error getting keys to delete: ${event}`);
          });

          keysToDelete.forEach((key) => {
            objectStore.delete(key);
          });
        }

        const addRequest = objectStore.add(newLog);
        addRequest.onsuccess = () => {
          resolve();
        };

        addRequest.onerror = (event) => {
          reject(`Error adding data to database: ${event}`);
        };
      } catch (error) {
        reject(`Error checking and updating entries: ${error}`);
      }
    };

    countRequest.onerror = (event) => {
      reject(`Error counting entries: ${event}`);
    };
  });
};

export const clearDataInDB = (db: IDBDatabase): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction("livelog", 'readwrite');
    const objectStore = transaction.objectStore("livelog");

    const request = objectStore.clear();

    request.onsuccess = () => {
      resolve();
    };

    request.onerror = (event) => {
      reject(`Error clearing data from database: ${event}`);
    };
  });
};



export const LiveLog: React.FC<{}> = () => {
  
  const navigate = useNavigate();
  const { startWebSocket, stopWebSocket } = useWebSocket();
  let rec  = useRec()
  const [logArr, setLogArr] = useState<any[]>([])
  let [wsStatus, setWsStatus] = useState("false")
  let [connection, setConnection] = useState({})

  const detailPageNav = ( navigate, url, id) => {  
    navigate(url, { state: { row: { id }, prevPageUrl: `/app/livelog`} })
  }


  const handleWebSocket = (key) => {
    if (key) {
      startWebSocket(true)
      setWsStatus("true")
    } else {
      stopWebSocket(true)
      setWsStatus("false")
    }
  };

  const clearLiveLog = async (key) => {
    localStorage.setItem('liveLogData', JSON.stringify([]));
    const db = await openDB("Log","livelog");
    clearDataInDB(db)
    setLogArr(p => []);
  }

  const gotLogMessage = (rec: LiveLogRecord) => {
  
    const line: any = [];
    if (rec) {
      let keyIdx = 1;

      if (rec.meta) {
        line.push(<div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>                 
        <FontAwesomeIcon title={rec.meta.level} icon={LogLevelIcon[rec.meta.level].icon} color={LogLevelIcon[rec.meta.level].color} size="xl" style={{ marginRight: '3px'}}></FontAwesomeIcon>
        {rec.meta.timestamp && (<span className="badge livelog-date" title="timestamp" key={keyIdx++}> {dateTimeString(rec.meta.timestamp + "")}</span>)}
        {rec.meta.event     && (<span className="badge livelog-event" title="event"     key={keyIdx++}> {rec.meta.event}</span>)}
        {rec.meta.lambda    && (<span className="badge livelog-lamdha" title="lambda"    key={keyIdx++}> {rec.meta.lambda}</span>)}      
        {rec.meta.connectionUuid && (
            <span 
              key={keyIdx++}
              className="badge livelog-connectionUuid" 
              title="connectionUuid"
              onClick={(e) => {
                  detailPageNav(navigate,`/app/connections/${rec.meta?.connectionUuid}/edit-profile`, rec.meta.connectionUuid)
              }}
              style={{ cursor: 'pointer' }}
            >
            {connection[rec.meta.connectionUuid]?.profile_name ?? rec.meta.connectionUuid}
          </span>
        )}
        {rec.meta.deviceUuid && (
          <span 
              key={keyIdx++}
              className="badge livelog-deviceUuid" 
              title="deviceUuid"
              onClick={(e) => {
                  detailPageNav(navigate,`/app/dmp-devices/${rec.meta?.deviceUuid}/device-detail`, rec.meta.deviceUuid)
              }}
              style={{ cursor: 'pointer' }}
            >
            {rec.meta.deviceUuid}
          </span>
        )}
        {rec.log[1]?.['error'] && (
          <span 
              key={keyIdx++}
              className="badge livelog-error" 
            >
          {rec.log[1]['error']}
          </span>
        )}
        </div>);
        line.push(
          <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
              <ObjectInspector key={keyIdx++} name={"META"} data={rec.meta} depth={2} />
          </div>
        )
      }
      if (rec.log) {
        if (Array.isArray(rec.log) && (rec.log.length % 2) === 0 && false) {
          const arr = rec.log;
          const arrayToObject = arr => {
          return arr.reduce((acc, curr, index) => {
              if (index % 2 === 0) {
                  const nextElement = arr[index + 1];
                  if (nextElement !== undefined && typeof curr === 'string' && curr.length > 1) {
                      return { ...acc, [curr.slice(0, -1)]: nextElement };
                  }
              }
              return acc;
          }, {});
        };
        const logObject = arrayToObject(arr);    
        line.push(
        <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
            <ObjectInspector key={keyIdx++} name={"args"} data={logObject.args} depth={2} />
        </div> 
        )
        line.push(
          <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
              <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
              <ObjectInspector key={keyIdx++} name={"info"} data={logObject.info} depth={2} />
          </div> 
        )} else {
          line.push(
            <div key={keyIdx++} style={{ display: 'flex', alignItems: 'center' }}>
                <FontAwesomeIcon title={rec.meta.level} icon={faArrowTurnDownRight} size="xl" style={{ marginRight: '3px' }} />
                <ObjectInspector key={keyIdx++} name={"LOG"} data={rec.log} depth={2} />
            </div> 
          )
        }
      }
  
      setLogArr(p => [<div key={p.length} className="livelog-div">{line}</div>,...p]);
    }
  };
  
  const pageButtons: PageButtonType[] = [
    { title: strings.LIVELOG_START_LOG, type: 'button_with_icon', action: () => handleWebSocket(true), icon: faPlay, visible: () => { return (wsStatus === "false") } },
    { title: strings.LIVELOG_STOP_LOG,  type: 'button_with_icon', action: () => handleWebSocket(false), icon: faStop, visible: () => { return (wsStatus === "true") } },
    { title: strings.LIVELOG_CLEAR,     type: 'button_with_icon', action: () => clearLiveLog(true), icon: faXmark }
  ];

  const breadCrumbArr: BreadCrumbType[] = [{ label: strings.NAV_SYSTEM, url: '' }, { label: strings.NAV_LIVELOG_ITEM, url: '' }];
  let webSocketStatus;

  useEffect(() => { gotLogMessage(rec) },[rec]);
  useEffect(() => { },[wsStatus]);
  useEffect(() => {
    const fetchData = async () => {

      try {
        const connection_list = await GenericDassQuery(`/rest/connections`)
        const connection_map = connection_list.data.reduce((acc, v) => {  acc[v.profile_uuid] = v; return acc;}, {});
        setConnection(connection_map)
        const db = await openDB("Log", "livelog");
        const data = await readDataFromDB(db, "livelog");
  
        if (Array.isArray(data) && data.length > 0) {
          for (let i = 0; i < data.length - 1; i++) {
            gotLogMessage(data[i]);
          }
        }
        webSocketStatus = localStorage.getItem('websocket_status') ?? "false";
        setWsStatus(webSocketStatus);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();
    console.log("Reading from LiveLog from IndexedDB");
  
  },[]);
  

  return (
    <div className="child-tab-wrapper">
      <PageHeader breadCrumbArr={breadCrumbArr} pageButtons={pageButtons} />
      <div className="schema-engine-simple-page">
        {logArr.length === 0 ? (
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img src={NoDataImage} alt="No Data" />
          { wsStatus == "false" ? ShowNoDataLiveLog(wsStatus) : ShowNoDataLiveLog(wsStatus)}
          <br/>
          { wsStatus == "false" && showButton(pageButtons[0]) }
      </div>
    ) : (
      logArr
    )}
    </div>
  </div>
  );

}
