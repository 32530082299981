import React, { useEffect, useState, useContext } from "react";
import { strings } from "./../../services/Localization";
import { checkLogin } from "./../../services/Login";
declare const constants;
import { useNavigate, useParams } from 'react-router-dom';
import { faTrashAlt , faAdd,  faRefresh, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_TAG, TAB_EDIT_TAG } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption,  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "./../../utils/Toaster";
import { getVisibleActions, actionIcon, elipsis } from "./../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import { isMobile } from "react-device-detect";
import { ShowSchemaAsModal } from "../../components/SchemaModal/SchemaModal";
import { formatText } from "../../schemaengine/client/SchemaTextParser";


interface ITagsStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh: boolean;
    isRowDeleted?: boolean;
}

interface IRowType {

    name: string;
    description: string;
    type: "device",
    target_id: string;
    target_service: string;

    has_parameters: boolean;
    show_on_ui: boolean;
    confirm: boolean;
}



const NamedServices: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: "Named Services", // FIXME 
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_NAMED_SERVICE_ITEM, url:''}], // FIXME
        refresh: false,
        isRowDeleted: false
    };

    const [tagState, setTagState] = useState<ITagsStates>(stateInit)

    useEffect(() => {
        
        if(checkLogin(AppContextObj.user)) {
            setTagState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }

    },[])

     const detailPageNav = ( navigate, tab, tagid, row) => {
        
        navigate(`${getAppBase()}/named-services/${tagid}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/named-services`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/named-services/${tab}`, {state: {prevPageUrl: `${getAppBase()}/named-services`}})
    }
    



    const execService = async (row: IRowType) => {

        const service = row;
        let schemaResponse;
        let requestBody = {};
        if (service.has_parameters) {
            schemaResponse = await ShowSchemaAsModal("/rest/nodes/" + row.target_id + "/services/" + row.target_service + "?uischema=true", true)
            if (typeof (schemaResponse) !== 'boolean' && schemaResponse.action == "apply" && schemaResponse.data) {
                requestBody = schemaResponse.data;
            } else {
                return;
            }
        }
        if (service.confirm) {
            const confirmDialogeSettings = {
                title: `${strings.EXECUTE_SERVICE_TITLE} "${service.name}"`,
                description: "",
                actionLabel: "execute",
                faIcon: faExclamationTriangle
            };
            if (await dialog(confirmDialogeSettings) !== true) { return }
        }

        try {
            const response = await GenericDassQuery("/rest/named-services/" + row.name + "/execute", {
                method: "POST",
                data: requestBody
            });
            if (response?.data?.results?.[0]?.statusCode && response.data.results[0].statusCode >= 200 && response.data.results[0].statusCode <= 299) {
                toast.success(strings.DEVICE_SERVICE_EXECUTE_SUCCESS);
            } else {
                if (response?.data?.results?.[0]?.error) {
                    toast.error(strings.DEVICE_SERVICE_EXECUTE_ERROR + ' ' + response?.data?.results?.[0]?.error);
                } else {
                    toast.error(strings.DEVICE_SERVICE_EXECUTE_ERROR_LIVELOG);
                }

            }
        } catch (e) {
            toast.error(e.message);   
        }
    }




    const getActions = () => {

        const actions: ActionType<IRowType>[] = [
        {
            type: "action",
            text: strings.EXECUTE_SERVICE_TITLE,
            visible: (row) => row.show_on_ui,
            render: (row, title) => formatText("[[fa-rocket-launch]]", "exec", null, null),
            action: (row) => execService(row)
        },
        {
            type: "action",
            text: strings.NAMED_SERVICE_DELETE,
            visible: () =>{ return AppContextObj.user && AppContextObj.user.can_delete_named_services && !AppContextObj.user._readonly },
            render: (row, title) => actionIcon(row.name, title, faTrashAlt.iconName),
            action: (group) => deleteTag(group)
        }];

        const bulkActions:BulkActionType<IRowType>[] = [
        
            {
                type: "action",
                text: strings.NAMED_SERVICES_DELETE,
                visible: () =>{ return AppContextObj.user && AppContextObj.user.can_delete_named_services && !AppContextObj.user._readonly },
                render: (row, title) => actionIcon(row.name, title, faTrashAlt.iconName),
                action: (selectedIds) => deleteTagInBulk(selectedIds)
            }
        ];

        return { actions, bulkActions };
       
    }


    const deleteTagInBulk =  async (selectedRows: IRowType[]) => {
        const tagIds = selectedRows.map((row) => {
            return row['name'];
        })
        if(tagIds && tagIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${tagIds.length} selected Named Service(s)`,
                description: await dialogDescription(tagIds) + "",
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(tagIds.map((tagid) => {
                        return GenericDassQuery("/rest/named-services/" + tagid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success("Bulk Named Services deleted successfully");
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteTag =  async (tag: IRowType) => {
        const confirmDialogeSettings = {
            title:  "Do you want to delete this Named Service",
            description: tag.name,
            actionLabel: 'Delete',
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/named-services/" + tag.name, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success("Named Service successfully deleted");

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

   const initDataTable = () => {
		
        const {actions, bulkActions} = getActions();
        
		const columns: ColumnType[] = [
            {
                key: "name",
                type: "text",
                title: "Name",
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },
                sortable: true,
                sortKey: "sort_by_name",
                detailLink: true,
                detailPageNav: (row: IRowType) =>  detailPageNav(navigate, TAB_EDIT_TAG, row.name, row ),
                customClass: 'nowarp',
                newCellWidth: isMobile ? '250px' : "25%"
            }, {
                key: "description",
                title: "Description",
                type: "text",
                inputValidation: "",
                filterable: true,
                filterField: 'search_description',
                filterType: 'text',
                filterParams: {
                    mapper: (x) => x || undefined
                },
                render: (row) => elipsis(row.description, 50),
                render_tooltip: (row) => row.description,
                newCellWidth: "55%"
            }, {
                key: "type",
                title: "Type",
                type: "text",
                inputValidation: "",
                newCellWidth: "10%"
            },
        ];



        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/named-services',
            query_param:{all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'name',
            oboe_path:'pages.*',
            available_key: 'name',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions,
            bulkActions: getVisibleActions(bulkActions)
        }

        return options;
	}
    
 

    const refreshTable = (isRowDeleted=false) => {

        setTagState(prevState => {
            return {...prevState, refresh:!prevState.refresh, isRowDeleted: isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: "Named Service",
                action: () => detailPageAddNav(navigate, TAB_ADD_TAG ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () =>{ return AppContextObj.user && AppContextObj.user.can_create_named_services && !AppContextObj.user._readonly }
            },
            {
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="named-services" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={tagState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={`Named-Services`} 
            emptyDataMsg={strings.NO_SERVICE_AVAILABLE}
            dataTableOption={initDataTable()} 
            isRowDeleted={tagState.isRowDeleted}
            refresh={tagState.refresh}>
        </PageContent>
    );

}


export default NamedServices;