// tslint:disable:max-line-length
import React, { ReactNode } from "react";

import { IJsonSchemaObject, IUiSchema, IUiSchemaDataResource, IUiSchemaObject, IUiSchemaLayoutOptions, IUiSchemaPanelLayoutOptions, IUiSchemaDropFile, IUiSchemaCardLayoutOptions, IResourceResponseObject, IUiActionButton, CardListElem } from "../UiJsonSchemaTypes";
import { ISchemaOptions, evalString, evalExpr, getObjectValues, ISchemaLib, proxyClone, updateConditionalSchema, evalSchemaElem, IInnerStates, IExprObjects, IExprScope, getRef, getPathAndKey, IControl } from "./SchemaTools";
import { IComponentHandlers, ITextMarkerHandlers, registeredExtensionLib } from "./SchemaExtensions";
import { ITextContext, ITextOptions, formatText } from "./SchemaTextParser";
import { schemaElemColLayout } from "./SchemaElemColLayout";
import { ArrayRow, renderArrayTable } from "./SchemaPanel";

// TODO:
// - add transformers. More convenient way to transform on load and apply. For example for map <-> array etc.
// - apply defaults after load
// - track changes
// - change and error per card and panel



// Controllers


export interface IUiSchemaUpdateState {
	currentValues?: any;
	lastValues?: any;

	objectErrors?: any;
	oldValues?: any;
	activeTab?: string;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
	ready?: boolean;

	update?: boolean;
	jsonSchema?: any;
}

export interface IUiSchemaSetValueResult {
	setValues?: any;	// set values and oldValues without merge
	oldValues?: any;	// merge properties into oldValues
	values?: any;		// merge properties into values
	errors?: any;		// merge properties into errors
	value?: any;		// merge [key] into values
	activeTab?: string;	// set active tab
	apply?: boolean;
	close?: boolean;
	success?: boolean;
	readOnly?: boolean;
	debug?: boolean;
	ready?: boolean;

	jsonSchema?: IJsonSchemaObject;		// root json schema
}

export interface IColumnElem {
	elem: ReactNode;
	options: {
		width?: number;
	};
}

interface IProfilingStat {
	min: number;
	max: number;
	total: number;
	avg: number;
	cnt: number;
}


interface IValueUpdate {
	value: any;
}

export interface IEmbedObjectOptions {
	useFlex?: boolean;
	isContainer?: boolean;
	noMargin?: boolean;
	noWrapper?: boolean;
}

export interface IUiSchemaElemArgs {
	key: string;
	fullkey: string;
	elem: IJsonSchemaObject;
	uiElem: IUiSchemaObject;
	readOnly: boolean;			// composite readOnly state, incl. modal readonly state
	elemReadOnly: boolean;		// readOnly state of only object.
	required: boolean;
	title: string;
	description: string;
	helpLink: string;

	layoutOptions?: IUiSchemaLayoutOptions;
	value: any;
	values: {
		[key: string]: any;
	};
	error: any;
	errors: any;
	enums: any[];
	enumLabels: {
		[key: string]: any;
	};
	update: (value: IValueUpdate) => void;
	dropFile?: IUiSchemaDropFile;

	type: string;

	objects: IExprObjects;
	lib: ISchemaLib,
	embedObject: (obj: ReactNode, options?: IEmbedObjectOptions) => ReactNode;
	getSettings: (scope: string) => any;
	stringToComponent: (text: string) => ReactNode;
}

export interface IUiSchemaCardArgs {
	key: string;
	readOnly: boolean;
	hasError: boolean;
	title: string;

	layoutOptions?: IUiSchemaCardLayoutOptions;
	actionButtons?: IUiActionButton[];

	updateValues: (values: IUiSchemaSetValueResult) => void;
	updateLayout: () => void;

	self: Self;
	getSettings: (scope: string) => any;
	stringToComponent: (text: string) => ReactNode;
}



interface IListener {
	keys?: string[];
	tabs?: string[];
	handle: () => void;
}


export interface IElemNode {
	jsxElem: ReactNode;
	args: IUiSchemaElemArgs;
}


interface ISchemaModalState {
//	oldRootJsonSchema: IJsonSchemaObject;
//	rootJsonSchema: IJsonSchemaObject;

//	componentHandlers: IComponentHandlers;
//	formComponents: IFormComponents;
//	cardHandlers: ICardHandlers;
//	textMarkersHandlers: ITextMarkerHandlers;
	updateCount: number;

//	listeners: IListener[] | null;
//	oldValues: any;
//	values: any;
//	objectErrors: any;

//	schemaOptions: ISchemaOptions;
//	lib: any;
}

interface ISchemaLocaleDictionary {
	"true": string;
	"false": string;
	click_to_unlock: string;
	cancel: string;
}

interface ISchemaCardList {
	title?: string;
	jsxElements: ReactNode[];
}

export interface IGetResourcesOptions {
	body?: any;
	headers?: {
		[header: string]: string;
	};
	chunkCallback?: (data: string, chunkIdx: number) => Promise<void>;
	responseObject: IResourceResponseObject;

	addAbortHandler: (handler: () => void) => void;
	removeAbortHandler: (handler: () => void) => void;

}


export interface ISchemaModalProps {
	jsonSchema?: IJsonSchemaObject;			// current schema
	jsonSchemaUrl?: string;

	object: any;
	updateState: (state: IUiSchemaUpdateState) => void;
	getResources: (method: string, url: string, options: IGetResourcesOptions) => Promise<{ ok: boolean, data: any, status?: number }>;
	showMessage: (type: "success" | "error" | "confirm", message: string) => Promise<boolean>;

	loadDataOnOpen: boolean;
	initialReadOnly: boolean;
	extensions?: IComponentHandlers;
	textMarkerExtensions?: ITextMarkerHandlers;
	libExtensions?: ISchemaLib;
	initialActiveTab: string;
	localeDictionary: ISchemaLocaleDictionary;
	lang?: string;			// language
	defaultLayoutOptions: IUiSchemaPanelLayoutOptions;
	debug?: boolean;
	log: (...args: any) => void;
	getSettings: (scope: string) => any;
	helpLinkCallback?: (link: string) => void;
}

export interface Self {
	objects: IExprObjects;
	lib: ISchemaLib;

	log: (...args: any) => void;

	componentHandlers: IComponentHandlers;
}


export interface ResourceStates {
	[id: string]: {
		initialLoad: boolean;
	}
}


export default class SchemaController extends React.Component<ISchemaModalProps, ISchemaModalState> {

	public busyWithResources = 0;
	public profiling: { [func: string]: IProfilingStat } = {};
	public intervalTimers: NodeJS.Timeout[] = [];
	public abortHandlers: Array<{handler: () => void, id: string }> = [];
	public innerStates: IInnerStates = {};

	public instantValues: any;
	public instantOldValues: any;
	public instantErrors: any;

	public componentHandlers: IComponentHandlers = {};
	public schemaOptions: ISchemaOptions;
	public resourceStates: ResourceStates = {};

	public control: IControl = {
		ready: false,
		activeTab: "",
		readOnly: false,
		modalReadOnly: false,
		apply: false,
	}

	listeners: IListener[] | null;

	// This object is updated at the beginning of each render cycle in top of render() and for each update with updateValues()
	public self: Self;
	public deferredUpdate: Array<() => void>;

    constructor(props: ISchemaModalProps) {
        super(props);

		const lib: ISchemaLib = {
			...this.props.libExtensions,
			...registeredExtensionLib,
			copyTextToClipboard: (text: string) => navigator.clipboard.writeText(text),
			log: (...args: any) => this.internalLog(...args),
			showMessage: (type: "success" | "error" | "confirm", msg: string) => this.props.showMessage(type, msg),
			abortResourceById: this.abortResourceById,
		}

		this.instantValues    = this.props.object || {};
		this.instantOldValues = this.instantValues;

		this.schemaOptions      = { treatNullAsUndefined: true, useDefaults: true, additionalProperties: true, contOnError: false, skipOnNullType: false, };
		const rootJsonSchema    = this.props.jsonSchema;
		const oldRootJsonSchema = rootJsonSchema;
		const uiSchema          = rootJsonSchema?.$uiSchema;
		const values            = proxyClone(this.instantValues    || {}, rootJsonSchema);
		const oldValues         = proxyClone(this.instantOldValues || {}, rootJsonSchema);

		const objects           = { values, oldValues, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema: rootJsonSchema,
									jsonSchema: rootJsonSchema, errors: {}, uiSchema, control: this.control } as IExprObjects;
		
		this.instantErrors      = this.checkObject(objects, lib, this.schemaOptions);
		objects.errors          = proxyClone(this.instantErrors, rootJsonSchema);

		this.control.activeTab = this.props.initialActiveTab;
		this.control.readOnly  = this.props.initialReadOnly;

        this.state = {
			updateCount: 0,
        };
		this.deferredUpdate = [];
		this.self = { 
			objects,
			lib,
			log: (...args: any) => this.internalLog(...args),
			componentHandlers: this.componentHandlers,
		};
	}


	public internalLog = (...args: any) => {
		this.props.log(...args);
	}


	
	public removeAbortHandler = (handler: () => void) => {
		const idx = this.abortHandlers.findIndex(h => h.handler === handler);
		if (idx >= 0) {
			this.abortHandlers.splice(idx, 1);
		}
	}
	public abortAll = () => {
		for (const handle of this.abortHandlers) {
			handle.handler();
		}
		this.abortHandlers.splice(0, this.abortHandlers.length);
	}
	public abortResourceById = (id: string) => {
		for (const handle of this.abortHandlers) {
			if (handle.id === id) {
				handle.handler();
			}
		}
	}



	public logTime(func: string, time: number) {
		const profile = this.profiling[func] = this.profiling[func] || {} as IProfilingStat;
		profile.cnt = (profile.cnt || 0) + 1;
		profile.min = profile.min == null || time < profile.min ? time : profile.min;
		profile.max = profile.max == null || time > profile.max ? time : profile.max;
		profile.total = (profile.total || 0) + time;
		profile.avg   = profile.total / profile.cnt;
	}

	public async applySync() {

		const props = this.props;
		const jsonSchema = this.props.jsonSchema;
		const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};
		let status = true;

		for (const dataResource of uiSchema.dataResources || []) {
			// exec immediately for onApply handlers.
			if (dataResource.triggerOnApply) {
				if (!await this.handleResource(dataResource, props, jsonSchema)) {
					status = false;
				}
			}
		}
		return status;
	}


	public checkObject(objects: IExprObjects, lib: ISchemaLib, schemaOptions: ISchemaOptions) {

		if (this.busyWithResources) {
			return {};
		}

		const ts0 = Date.now();

		const schema = objects.rootCondJsonSchema || {};
		const errObj = evalSchemaElem(schema, schema, objects.values, this.innerStates,
										{ ...schemaOptions, skipOnNullType: true, contOnError: true }, lib, objects) || {};
		const ts1 = Date.now();
		this.logTime("checkobject", ts1-ts0);
		return errObj;
	}


	public handleNewSchemaResources(props: ISchemaModalProps, jsonSchema: IJsonSchemaObject): IListener[] {

		// schema is changed. We need to update the listeners
		const uiSchema: IUiSchema = jsonSchema.$uiSchema || {};
		const listeners: IListener[] = [];

		// First clear all existing (in any) interval timers
		for (const it of this.intervalTimers) {
			clearInterval(it);
		}
		this.intervalTimers = [];

		for (const dataResource of uiSchema.dataResources || []) {
			const id = dataResource.resourceId || "__default__";

			// For periodic triggers, create the interval timer and push the handle to the intervalTriggers array
			// so we can clear the timers again later when we close the view.
			if (dataResource.triggerPeriodicallyEveryNumSeconds) {
				this.intervalTimers.push(setInterval(() => {
					this.handleResource(dataResource, props, jsonSchema);
				}, dataResource.triggerPeriodicallyEveryNumSeconds * 1000));
			}

			// setup trigger
			let firstTriggerOnValue = false;
			if (dataResource.triggerOnValuesChange) {
				const { keypath } = getPathAndKey(dataResource.targetKey || "");
				const path = keypath ? keypath + "/" : "";
				const watchKeys = dataResource.triggerOnValuesChange.map(fk => fk.startsWith("/") ? fk : path + fk);

				listeners.push({
					keys: watchKeys,
					handle: () => { this.handleResource(dataResource, props, jsonSchema) },
				});
				for (const key of watchKeys) {
					if (this.self.objects.values[key] != null) {
						firstTriggerOnValue = true;
					}
				}
			}
			if (dataResource.triggerOnSetTabs) {
				listeners.push({
					tabs: dataResource.triggerOnSetTabs,
					handle: () => { this.handleResource(dataResource, props, jsonSchema) },
				});
				if (dataResource.triggerOnSetTabs.includes(this.self.objects.control.activeTab)) {
					firstTriggerOnValue = true;
				}
			}

			// exec immediately for onOpen handlers.
			if (!this.resourceStates[id]?.initialLoad &&
				(dataResource.triggerOnOpen || (dataResource.triggerOnOpenOnLoadRequest && this.props.loadDataOnOpen) || firstTriggerOnValue)) {
				this.handleResource(dataResource, props, jsonSchema);
				if (id !== "__default__") {
					this.resourceStates[id] = { ...this.resourceStates[id], initialLoad: true }			
				}
			}
		}

		// Finally set the __default__ so we don't do initial update again for any resource without id.
		this.resourceStates["__default__"] = { ...this.resourceStates["__default__"], initialLoad: true }

		return listeners;
	}


	private async handleResource(dataResource: IUiSchemaDataResource, props: ISchemaModalProps, jsonSchema: IJsonSchemaObject) {

		const self = this.self;
		try {
			this.busyWithResources++;

			const lib = self.lib;
			const control = self.objects.control;
			
			const oldValues: any = getObjectValues(self.objects.rootCondJsonSchema, this.instantOldValues, 
										{ treatNullAsUndefined: true, useDefaults: false, additionalProperties: true,
											contOnError: false, skipOnNullType: false }, lib, this.self.objects);
			const newValues: any = getObjectValues(self.objects.rootCondJsonSchema, this.instantValues,
										{ treatNullAsUndefined: true, useDefaults: true, additionalProperties: true,
											contOnError: false, skipOnNullType: false }, lib, this.self.objects);

			const diffValues: any = {};
			for (const key of Object.keys(newValues)) {
				if (newValues[key] !== oldValues[key]) {
					diffValues[key] = newValues[key];
				}
			}

			self.log("objects", self.objects);
			self.log("old values", oldValues);
			self.log("new values", newValues);
			self.log("diff values", diffValues);

			const { key, keypath } = getPathAndKey(dataResource.targetKey || "");

			let objects = { ...self.objects, newValues, diffValues,
							values: keypath ? self.objects.values[keypath + "?"] : self.objects.values,
							errors: keypath ? self.objects.errors[keypath + "?"] : self.objects.errors };

			let readOnly = !!(control.modalReadOnly || control.readOnly);

			if (dataResource.confirmMessage) {
				const msgStr = evalString(dataResource.confirmMessage, lib, objects, { readOnly });
				if (msgStr  &&  !await this.props.showMessage("confirm", msgStr + "")) {
					this.busyWithResources--;
					return true;
				}
			}

			let headers: any = undefined;
			if (dataResource.headers)     { headers = {...headers, ...dataResource.headers}; }
			if (dataResource.contentType) { headers = {...headers, "Content-Type": dataResource.contentType }; }

			const body = dataResource.body ? evalString(dataResource.body, lib, objects, { readOnly }) : null;
			const url = evalString(dataResource.url, lib, objects, { readOnly });

			self.log("Resource", dataResource.url, url, headers, body);

			const responseObject: IResourceResponseObject = {} as any;
			const chunkCallback = async (value: string) => {

				if (dataResource.setValue) {	
					const objects = { ...this.self.objects, newValues, diffValues,
						    	      values: keypath ? this.self.objects.values[keypath + "?"] : this.self.objects.values,
									  errors: keypath ? this.self.objects.errors[keypath + "?"] : this.self.objects.errors };
					const targetObj = evalExpr(dataResource.setValue, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, null);
					this.updateValues(targetObj, keypath, key);
				}
			};

			let data: { ok: boolean, status?: number, data: any } = { ok: true, data: null };

			if (url && typeof url === "string") {

				// If a resource ID is provided set a variable in the scope __resourceid__xx_started = epoc. This can be used to make conditional
				// statements in the schema to show e.g. cancel buttons, or ghost the trigger button, etc.
				//
				if (dataResource.resourceId) {
					this.updateValues({ values: { ["__resourceid_" + dataResource.resourceId + "_started"]: Date.now() } }, keypath, key);
				}

				// We encapsulate the IO request in a local try/catch to be sure that if an exception happen, we complete and clear the
				// resource variable again.
				let exception: any = null;
				try {
					data = await props.getResources((dataResource.method || "get").toUpperCase(), url,
					{ headers, body, responseObject, chunkCallback,
						addAbortHandler: (handler) => this.abortHandlers.push({ handler, id: dataResource.resourceId || "" }),
						removeAbortHandler: this.removeAbortHandler })

				} catch (e) {
					exception = e;
				}

				if (dataResource.resourceId) {
					this.updateValues({ values: { ["__resourceid_" + dataResource.resourceId + "_started"]: 0 } }, keypath, key);
				}

				if (exception) { throw exception; }
			}

			self.log("Resource result", url, data);

			if (!data.ok && data.status === -1) {
				// Got abort, just exit without further fuss
				this.busyWithResources--;
				return false;
			}


			// Update values again after the await
			objects = { ...this.self.objects, newValues, diffValues,
						values: keypath ? this.self.objects.values[keypath + "?"] : this.self.objects.values,
						errors: keypath ? this.self.objects.errors[keypath + "?"] : this.self.objects.errors };
			readOnly = !!(control.modalReadOnly || control.readOnly);

			const value = data.data;
			const status = data.status;
			const lang = this.props.lang || "";
			let evtxt = "";

			// Depending if the call returns success or error, we evaluate one for the showMessageOnSuccess or showMessageOnError
			// These string are parsed with evalString and can therefor contain expressions. The normal returned value is a string,
			// however it is also possible to return an object with the following:
			//    { type: "success" | "error", message: string }
			// This way it is possible to popup an error dialog on an success and vice versa.
			// An empty return value ("" or null) will not show any message

			if (data.ok && dataResource.showMessageOnSuccess) {
				evtxt = ((lang && (dataResource as any)["showMessageOnSuccess[" + lang + "]"]) || dataResource.showMessageOnSuccess);
			}
			if (!data.ok && dataResource.showMessageOnError !== "") {
				evtxt = ((lang && (dataResource as any)["showMessageOnError[" + lang + "]"]) || dataResource.showMessageOnError) || "{{value}}";
			}
			if (evtxt) {
				const msgtxt: any = evalString(evtxt, lib, objects, { value, status, readOnly }) as string;
				if (msgtxt) {
					const type: any   = (typeof msgtxt === "object" && msgtxt.type)    ? msgtxt.type    : (data.ok ? "success": "error");
					const msg: string = (typeof msgtxt === "object" && msgtxt.message) ? msgtxt.message : msgtxt as string;
					msg && props.showMessage(type, msg);
				}
			}


			let continueOnError = false;
			if (!data.ok && dataResource.continueOnError != null) {
				continueOnError = typeof dataResource.continueOnError === "string" 
							? evalExpr(dataResource.continueOnError, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, Error)
							: dataResource.continueOnError;
			}

			let targetObj: IUiSchemaSetValueResult = {};
			if (data.ok || continueOnError) {
				targetObj = dataResource.setValue
						? evalExpr(dataResource.setValue, lib, objects, { fullkey: dataResource.targetKey, readOnly, value, status, responseObject }, Error)
						: { value };
				self.log("setValues", dataResource.setValue, targetObj);
			}

			// If the IO fail we abort the apply
			// TODO: allow more control on this.
			if (!data.ok && !continueOnError) { targetObj = { apply: false, ...targetObj }; }

			this.busyWithResources--;
			this.updateValues(targetObj, keypath, key);

			return data.ok;

		} catch (e) {
			self.log(e);
			this.busyWithResources--;
			return false;
		}
	}




	public updateValues(targetObj: IUiSchemaSetValueResult, keyPath: string, key: string) {

		const self = this.self;

		if (targetObj instanceof Promise) {
			targetObj.then(res => this.updateValues(res, keyPath, key));
			return;
		}
		if (!targetObj || Object.keys(targetObj).length === 0) {
			self.log("Update with empty object")
			return;
		}


		function objectMerge(dstObj: any, srcObj: any) {

			for (const k of Object.keys(srcObj)) {
				const path = (k.startsWith("/") ? k.substring(1) : (keyPath ? keyPath + "/" : "") + k).split("/");

				// Handle the .. and . operators
				for (let i = 0; i < path.length; i++) {
					if (path[i] === "..") {
						if (i > 0) { path.splice(i - 1, 2);
						} else { path.splice(i, 1); }
						i--;
					} else if (path[i] === ".") {
						path.splice(i, 1);
						i--;
					}
				}

				const key = path.pop() as string;
				let cv = dstObj;
				for (const pe of path) {
					const cve = cv && cv[pe];
					cv = cv[pe] = typeof cve === "object" ? (Array.isArray(cve) ? [...cve] : {...cve}) : {};
				}
				cv[key] = srcObj[k];
			}
			return dstObj;
		}

		const currentValues = this.instantValues;
		const cpValues = {...currentValues};
		const setValues = targetObj.setValues ? objectMerge({}, targetObj.setValues) : null;
		const { schemaOptions } = this;
		const lib = self.lib;

		const update: IUiSchemaUpdateState = Object.assign({ lastValues: currentValues },
			targetObj.hasOwnProperty("value") && { currentValues: objectMerge(cpValues, { [key]: targetObj.value }) },
			targetObj.values              && { currentValues: objectMerge(cpValues, targetObj.values) },
			targetObj.setValues           && { currentValues: setValues, oldValues: setValues },
			targetObj.oldValues           && { oldValues:     objectMerge({...this.instantOldValues },  targetObj.oldValues) },
			targetObj.errors              && { objectErrors:  targetObj.errors },
			targetObj.close != null       && { close:     targetObj.close },
			targetObj.success != null     && { success:   targetObj.success },
			targetObj.debug != null       && { debug:     targetObj.debug },

			// For attempts to update the Schema, this is only allowed when the allowSchemaUpdate setting is true
			(targetObj.jsonSchema && (self.objects.rootJsonSchema?.$uiSchema?.settings?.allowSchemaUpdate || !self.objects.rootJsonSchema)) && { jsonSchema: targetObj.jsonSchema },
		);

		self.log("Updating values", keyPath, key, targetObj, update);

		let oldObjects = self.objects

		if (update.jsonSchema || update.currentValues || update.oldValues || update.objectErrors) {

			this.instantValues    = update.currentValues || this.instantValues;
			this.instantOldValues = update.oldValues     || this.instantOldValues;

			const rootJsonSchema  = update.jsonSchema || this.self.objects.rootJsonSchema;
			const values    = update.currentValues || update.jsonSchema ? proxyClone(this.instantValues,    rootJsonSchema) : self.objects.values;
			const oldValues = update.oldValues     || update.jsonSchema ? proxyClone(this.instantOldValues, rootJsonSchema) : self.objects.oldValues;
			const objects   = { ...self.objects, rootJsonSchema, values, oldValues };

			objects.rootCondJsonSchema = updateConditionalSchema(rootJsonSchema || {}, objects.values, schemaOptions, lib, objects);
			objects.jsonSchema         = objects.rootCondJsonSchema;
			this.instantErrors         = { ...this.checkObject(objects, lib, schemaOptions), ...update.objectErrors };
			objects.errors             = proxyClone(this.instantErrors, rootJsonSchema)
			this.self.objects               = objects;

			if (update.jsonSchema) {
				this.listeners = this.handleNewSchemaResources(this.props, rootJsonSchema);
			}

			self.log("UPDATED OBJECTS", this.self.objects);

		}

		let oldControl = this.control;
		let control = this.control;

		if (targetObj.ready != null)     { control = {...control, ready: targetObj.ready };       update.update = true; }
		if (targetObj.activeTab != null) { control = {...control, activeTab: targetObj.activeTab }; }
		if (targetObj.readOnly != null)  { control = {...control, readOnly: targetObj.readOnly }; update.update = true; }
		if (targetObj.apply != null)     { control = {...control, apply: targetObj.apply };       update.update = true; }
		if (Object.keys(self.objects.errors).length !== Object.keys(oldObjects.errors).length) {  update.update = true; }

		this.control = control;


		// Handle the general readonly of the whole modal
		const uiSchema = self.objects.rootJsonSchema?.$uiSchema;
		let modalReadOnly = false;
		if (uiSchema?.modal?.readOnly) {
			if (typeof uiSchema.modal?.readOnly === "boolean") {
				modalReadOnly = uiSchema.modal?.readOnly;
			} else if (typeof uiSchema.modal?.readOnly === "string") {
				const readOnly = !!this.control.readOnly;
				modalReadOnly = evalExpr(uiSchema.modal.readOnly, lib, self.objects, { schema: self.objects.rootJsonSchema, readOnly }, false);
			}
		}
		if (this.control.modalReadOnly !== modalReadOnly) {
			update.update = true;
			this.control = { ...this.control, modalReadOnly };
		}

		
		if (oldControl != this.control) {
			self.objects = { ...self.objects, control: this.control };
		}


		this.valuesUpdated(oldObjects, self.objects);
		this.props.updateState(update);

		this.setState({ updateCount: this.state.updateCount + 1 });

		self.log("objects", self.objects);
	}






	/**
	 * this is called after the updateValues() function has run and new values are set.
	 * This function will perform all the functions that are triggered by any change of value.
	 */
	public valuesUpdated(oldObjects: IExprObjects, newObjects: IExprObjects) {

		const self = this.self;
		const oldControl = oldObjects.control;
		const newControl = newObjects.control;

		let resourceTriggered = false;

		if (oldObjects.values !== newObjects.values) {

			// check listeners
			for (const listener of this.listeners || []) {
				const cValues = newObjects.values;
				const pValues = oldObjects.values;

				for (const key of listener.keys || []) {
					if (cValues[key] !== pValues[key]) {
						self.log("Resource triggered " + key + " change " + cValues[key] + "!==" + pValues[key]);
						resourceTriggered = true;
						listener.handle();
						break;
					}
				}
			}
		}

		if (oldControl !== newControl) {

			if (newControl.activeTab !== oldControl.activeTab) {
				// Check triggers on tabs
				for (const listener of this.listeners) {
					if (listener.tabs && listener.tabs.includes(newControl.activeTab)) {
						self.log("Resource triggered on tab " + newControl.activeTab);

						resourceTriggered = true;
						listener.tabs = null;	// trigger only once
						listener.handle();
					}
				}
			}
		}


		if (newControl.apply) {

			const { rootJsonSchema } = this.self.objects;
			const uiSchema = rootJsonSchema.$uiSchema;

			if (!oldControl.apply) {
				for (const datres of uiSchema.dataResources || []) {
					// exec immediately for onApply handlers.
					if (datres.triggerOnApply) {

						resourceTriggered = true;

						self.log("Resource on apply triggered");
						this.handleResource(datres, this.props, rootJsonSchema);
					}
				}
			}

			// Finally, we close the dialog when three is no more IO ongoing
			if (!this.busyWithResources && !resourceTriggered) {
				this.updateValues({ close: uiSchema.modal?.closeOnApply !== false, success: true, apply: false }, "", "");
			}
		}

	}


	public componentDidUpdate(prevProps: ISchemaModalProps, prevState: ISchemaModalState) {

		const self = this.self;
		const { rootJsonSchema } = self.objects;

		// before having a schema we just return
		if (!rootJsonSchema) { return; }


		// Run deferred updates
		for (const cb of this.deferredUpdate || []) {
			cb();
		}
		this.deferredUpdate = [];


		if (prevProps.debug !== this.props.debug && this.props.debug) {
			self.log("objects", this.self.objects);
			self.log("values", this.instantValues);
			self.log("errors", this.instantErrors);
			self.log("schema", rootJsonSchema);
			self.log("cond-schema", this.self.objects.rootCondJsonSchema);
			self.log("profiling", this.profiling);
		}


	}


    public async componentDidMount() {
        // document.addEventListener("mousedown", this.handleClickOutside);

        try {

			const req = await this.props.getResources("GET", this.props.jsonSchemaUrl, {
				responseObject: {} as any, 
				addAbortHandler: (handler) => this.abortHandlers.push({ handler, id: "__load_schema__" }),
				removeAbortHandler: this.removeAbortHandler
			});

			if (req.ok) {

				const rootJsonSchema: IJsonSchemaObject = req.data;
				this.self.objects.oldRootJsonSchema = rootJsonSchema;
				
				if (rootJsonSchema.$uiSchema?.libFunctions) {
					for (const funcName of Object.keys(rootJsonSchema.$uiSchema?.libFunctions)) {
						try {
							this.self.lib[funcName] = eval("(" + rootJsonSchema.$uiSchema?.libFunctions[funcName] + ")");
						} catch (e) {
							console.log("Error adding lib function " + funcName, e.message);
						}
					}
				}

				this.updateValues({
					jsonSchema: rootJsonSchema,
					ready: rootJsonSchema?.$uiSchema?.modal?.ready !== false,
					...(typeof rootJsonSchema?.$uiSchema?.modal?.readOnly === "boolean" ? { readOnly: rootJsonSchema.$uiSchema.modal.readOnly } : null)
				}, "", "");

			}


        } catch (error) {
            console.log(error);
        }
    }


    public componentWillUnmount() {
		for (const it of this.intervalTimers) {
			clearInterval(it);
		}
		this.abortAll();
		this.intervalTimers = [];
    }



	public getFirstTabKey() {

		const uiSchema: IUiSchema = this.self.objects.rootJsonSchema?.$uiSchema || {};
		if (uiSchema && uiSchema.panels) {
			for (const tabKey of Object.keys(uiSchema.panels)) {
				const tab = uiSchema.panels[tabKey];
				if (tab.title) { return tabKey; }
			}
		}
		return undefined;
	}


	public formatTextArgs(text: string, args: IUiSchemaElemArgs, key?: string) {

		const self = this.self;
		const { objects, lib } = self;

		let scope: IExprScope = { readOnly: objects.control.readOnly };

		if (args) {
			const { value, readOnly, fullkey, error } = args;
			scope = { value, readOnly, fullkey, error };
		}

		const options: ITextOptions = { log: (...args: any[]) => self.log(...args), debug: this.props.debug }
		const context: ITextContext = { args, lib, objects, scope,
					updateValues: (...args) => this.updateValues(...args) };

		return formatText(text, key || args?.key, options, context);

	}


	public parseAndFormatText = (text: string, key: string) => {

		if (!text) { return null; }
		const { objects, lib } = this.self;
		const readOnly = !!(objects.control.modalReadOnly || objects.control.readOnly)
		const txt = evalString(text, lib, objects, { readOnly }) + "";
		const jsx = this.formatTextArgs(txt, null as any, key);

		return jsx;
	}




	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public addTabElem(tabkey: string, isActive: boolean, hasError: boolean, title: ReactNode, onClick: () => void) {
		// Should be overriden by extended class
		return <div></div>;
	}


    public getTabItems(activeTabKey: string, objects: IExprObjects) {
        const errors = this.instantErrors;
        const jsxElems: JSX.Element[] = [];
		const rootCondJsonSchema = objects.rootCondJsonSchema;
		const uiSchema: IUiSchema = rootCondJsonSchema?.$uiSchema || {};
		const readOnly = !!(this.self.objects.control.modalReadOnly || this.self.objects.control.readOnly);
		const lib = this.self.lib;


		if (!uiSchema || !uiSchema.panels) { return []; }

        for (const tabKey of Object.keys(uiSchema.panels)) {
            const tab = uiSchema.panels[tabKey];
			const hidden = tab.hidden && evalExpr(tab.hidden, lib, objects, { readOnly }, false);
			if (hidden) { continue; }

			if (tab.title) {

				let hasElements = false;
				let hasError    = false;

				const panelCardsMap = {};
				flatCardList(panelCardsMap, tab.cards);
	

				for (const cardKey of Object.keys(panelCardsMap)) {
					const card = uiSchema.cards && uiSchema.cards[cardKey];
					if (card?.hidden && evalExpr(card.hidden, lib, objects, { readOnly }, false)) { continue; }

					for (const elemKey of card?.properties || []) {

						if (!hasElements) {
							let schema: IJsonSchemaObject | undefined = rootCondJsonSchema;
							let elemPresent = true;
							for (const key of elemKey.split(/[/]/)) {
								schema = schema?.properties && schema.properties[key];
								if (!schema) { elemPresent = false; break; }
							}

							if (schema?.$uiSchemaObject?.hidden != null) {
								const hidden = schema.$uiSchemaObject.hidden;
								if (hidden === true || (typeof hidden === "string" && 
												evalExpr(hidden, lib, objects, { fullkey: elemKey, readOnly, schema }, false))) {
									elemPresent = false;
								}
							}

							hasElements ||= elemPresent;
						}

						if (errors && !hasError) {
							let elemHasError = true;
							let obj          = errors;
							for (const key of elemKey.split(/[./]/)) {
								obj = obj[key];
								if (!obj) { elemHasError = false; break; }
							}
							hasError ||= elemHasError;
						}
					}
				}
				if (!hasElements && hidden !== false) { continue; }

				const lang = this.props.lang;
				const title: string = (lang && (tab as any)["title[" + lang + "]"]) || tab.title;
				const titleText = title && evalString(title, lib, objects, { readOnly }) + "";
				const titleObj = titleText && this.formatTextArgs(titleText, null, tabKey);
				jsxElems.push(this.addTabElem(tabKey, activeTabKey === tabKey, hasError, titleObj,
											() => this.updateValues({ activeTab: tabKey }, "", "")));
			}
        }

        return jsxElems;
    }



	public embedArrayContainer(args: IUiSchemaElemArgs,  schema: IJsonSchemaObject,
								elements: ReactNode, add: () => void, boxType: "embox" | "table" | "accordion" | "card") {
		// Override in extended class
		return <div>{elements}</div>;
	}

	public embedArrayElement(key: string, element: ReactNode, rem: () => void, add: () => void) {
		// Override in extended class
		return <div>{element}</div>;
	}

	public embedArrayElementObject(args: IUiSchemaElemArgs, elements: ReactNode, boxType: "embox" | "table"| "accordion",
								   rem: () => void, add: () => void) {

		// Override in extended class
		return <div>{elements}</div>;
	}



	public embedObject(args: IUiSchemaElemArgs, obj: ReactNode, options?: IEmbedObjectOptions) {
		// Override in extended class
		return <div>{obj}</div>;
	}


	


	/**
	 * getOrderedKeys take the list of keys and lookup the objects to retrieve ordering information
	 * in the schema data, such as the uischema idx field.
	 * 
	 * @param keys 
	 * @param obj 
	 * @returns 
	 */
	public getOrderedKeys(keys: string[], obj: { [key: string]: IJsonSchemaObject }) {

		return keys.sort((a, b) => {
			const ia = obj[a]?.$uiSchemaObject?.order ?? obj[a]?.$uiSchemaObject?.idx;
			const ib = obj[b]?.$uiSchemaObject?.order ?? obj[b]?.$uiSchemaObject?.idx;
			return ia == null || ib == null ? 0 : ia - ib;
		});
	}


	/**
	 * renderSchema
	 * Main render function to generate a composite react component created based on
	 * the schema.
	 *
	 * @param card - name of card in UISchema to be rendered
	 * @returns React component
	 */

	public renderSchema(card: string, rootCondJsonSchema: IJsonSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions) {

		const jsxElems: ReactNode[] = [];
		const cards: ISchemaCardList[] = [{ jsxElements: jsxElems }];
        const uiSchema: IUiSchema = rootCondJsonSchema?.$uiSchema || {};
		const { lang } = this.props;
		const self        = this.self;
		const lib         = self.lib;
		const control     = self.objects.control;
		const oldValues   = self.objects.oldValues;
		const valuesProxy = self.objects.values;
		const errorsProxy = self.objects.errors;
		const { oldRootJsonSchema, rootJsonSchema } = self.objects;
		
		let hasError = false;

        if (!rootCondJsonSchema) { return { hasError, cards: [] }; }

		const cardProps = uiSchema && uiSchema.cards && uiSchema.cards[card];
        const keys = cardProps?.properties;



		const parseArray = (args: IUiSchemaElemArgs,
							layoutOptions: IUiSchemaPanelLayoutOptions,
							pushElem: (elem: ReactNode, args: IUiSchemaElemArgs) => void) => {

			if (!args.elem.items) {
				self.log("Missing items in", args.elem);
				return;
			}

			const { fullkey, required, objects, elem } = args;
			const { values, jsonSchema } = objects;
			const uiElem      = elem?.$uiSchemaObject || {};
			let   itemElem    = elem.items;
			
			if (itemElem.$ref) { itemElem = getRef(itemElem, rootCondJsonSchema, true); }

			const itemUiElem  = itemElem.$uiSchemaObject || {};

			const arrayElems: ReactNode[] = [];
			const arrayRows: ArrayRow[] = [];		// New - first for tables only


			const arrayValuesProxy = valuesProxy[fullkey + "?"];
			const arrayErrorsProxy = errorsProxy[fullkey + "?"];
			const arrayValues      = arrayValuesProxy["."] || [];
			const arrayRenderMode  = uiElem.arrayElementRenderMode || layoutOptions.arrayElementRenderMode;

			const editArray = typeof uiElem.editArray === "string" ?
								evalExpr(uiElem.editArray, lib, objects, 
									{ fullkey: args.fullkey, value: args.value, readOnly: args.readOnly, error: args.error, schema: elem }, false) :
									uiElem.editArray;

			const boxType = itemUiElem.type === "table" ? "table" : (itemUiElem.type === "card" ? "card" : "accordion");
			const arrLayoutOptions = {...layoutOptions};
			if (boxType === "table") {
				arrLayoutOptions.titleLayout       = "none";
				arrLayoutOptions.descriptionLayout = "popup";
			}

			// This function will update a __acc_ prefixed control variable to show the
			// active entry in the array accordion
			const setActive = (activeArrayKey: string) => {
				if (boxType === "accordion") {
					const acckey = "__acc_" + fullkey.replace(/[/]/g, ".");
					this.updateValues({ values: { [acckey]: activeArrayKey }}, "", "" );
				}
			}

			// 

			let arrControls: string[] = [];
			if (editArray && !args.readOnly) {
				if (Array.isArray(uiElem.editArrayControls)) {
					arrControls = uiElem.editArrayControls;
				} else if (typeof uiElem.editArrayControls === "string") {
					arrControls = evalExpr(uiElem.editArrayControls, lib, objects, { fullkey: "", value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined);
				}
			}
			const canAppend = Array.isArray(arrControls) && arrControls.includes("append")  && (elem.maxItems == null || arrayValues.length < elem.maxItems);


			const emptyArray = uiElem.treatEmptyAs ? evalExpr(uiElem.treatEmptyAs, lib, objects,
							{ fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, []) : [];


			for (let idx = 0; idx < arrayValues.length; idx++) {

				let  arElem   = (elem.itemsArray && elem.itemsArray[idx]) || itemElem;
				if (arElem.$ref) { arElem = getRef(arElem, rootCondJsonSchema, true); }


				const arUiElem = itemElem.$uiSchemaObject || {};
				const arFullkey = fullkey + "/" + idx;
				const rIdx = idx;

				const arrayArgs = getArgs(jsonSchema, arElem, arUiElem, layoutOptions, fullkey, idx, arrayValuesProxy, arrayErrorsProxy, required);
				if (!arrayArgs) { continue; }


				let arrControls: string[] = [];
				if (editArray && !args.readOnly) {
					if (Array.isArray(uiElem.editArrayControls)) {
						arrControls = uiElem.editArrayControls;
					} else if (typeof uiElem.editArrayControls === "string") {
						arrControls = evalExpr(uiElem.editArrayControls, lib, objects, { fullkey: arrayArgs.fullkey, value: arrayArgs.value, error: arrayArgs.error, readOnly: arrayArgs.readOnly, schema: arElem }, undefined);
					}
				}
				const canInsert = Array.isArray(arrControls) && arrControls.includes("insert") && (elem.maxItems == null || arrayValues.length < elem.maxItems)
				const canDelete = Array.isArray(arrControls) && arrControls.includes("delete") && (elem.minItems == null || arrayValues.length > elem.minItems)


				const deleteHandle = () => {
					const arr = [...args.value];
					arr.splice(rIdx, 1);
					args.update({ value: arr.length === 0 ? emptyArray : arr });
				};
				const insertHandle = () => {
					const arr = [...args.value];
					const newElem = uiElem.editArrayAddElement
							? evalExpr(uiElem.editArrayAddElement, lib, objects, { fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined)
							: (itemElem.type === "object" ? {} : itemElem.type === "array" ? [] : undefined);

					arr.splice(rIdx, 0, newElem);
					args.update({ value: arr });
					setActive(String(rIdx));
				};

				// determine if this element content should be rendered.
				let renderElemContent = true;
				if (boxType === "accordion") {
					renderElemContent = arrayRenderMode === "render-always" || values["/__acc_" + fullkey.replace(/[/]/g, ".")] === idx;
				}

				if (arElem.type === "object") {

					const arrayJsxElemObjects: ReactNode[] = [];
					let arrayColElems: IElemNode[] = [];

					const arrayPushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => 
														arrayColElems.push({ jsxElem, args });

					if (renderElemContent) {

						const orderedKeys = this.getOrderedKeys(Object.keys(arElem.properties || {}), arElem.properties);
						parseObject(orderedKeys,							// keys of object
									arFullkey,								// absolute path of the object
									arElem,									// schema
									arrLayoutOptions,
									arrayPushElem);
					}


					if (boxType === "card") {

						// For a card layout we need to render to a layout
						schemaElemColLayout(arrayColElems, arrayJsxElemObjects as JSX.Element[], layoutOptions.numColumns || 0);

						// TODO: add controls (add, delete, etc)
						cards.push({ jsxElements: arrayJsxElemObjects });

					} else if (boxType === "accordion") {

						// For a according layout we need to render to a layout
						schemaElemColLayout(arrayColElems, arrayJsxElemObjects as JSX.Element[], layoutOptions.numColumns || 0);

						arrayElems.push(this.embedArrayElementObject(arrayArgs, arrayJsxElemObjects, boxType,
														canDelete && deleteHandle, canInsert && insertHandle));
					} else if (boxType === "table") {

						arrayRows.push({ 
							elemNodes:  arrayColElems,
							controls: {
								add: canInsert ? insertHandle : null,
								rem: canDelete ? deleteHandle : null,
							}
						})

					}

				} else if (arElem.type === "array") {

					let jsxArrElem: ReactNode = null;
					parseArray(arrayArgs, arrLayoutOptions, (jsxElem: ReactNode, args: IUiSchemaElemArgs) => { jsxArrElem = jsxElem});

					if (jsxArrElem) {
						if (boxType === "card") {
							// TODO: add controls (add, delete, etc)
							cards.push({ jsxElements: [jsxArrElem] });
						} else {
							arrayElems.push(this.embedArrayElementObject(arrayArgs, jsxArrElem, boxType,
															canDelete && deleteHandle, canInsert && insertHandle));
						}
					}

				} else {

					if (arrayArgs && arrayArgs.type && this.componentHandlers[arrayArgs.type]) {
						const obj = this.componentHandlers[arrayArgs.type](arrayArgs);

						if (obj && !(obj as JSX.Element).key) { console.log("Missing key in array", arrayArgs, args); }

						obj && arrayElems.push(this.embedArrayElement(idx + "", obj, canDelete && deleteHandle,
																	  canInsert && insertHandle));
					}
				}

			}

			// Prepare add new array element handle
			const addHandle = () => {
				const newElem = uiElem.editArrayAddElement
							? evalExpr(uiElem.editArrayAddElement, lib, objects, { fullkey, value: args.value, error: args.error, readOnly: args.readOnly, schema: elem }, undefined)
							: (itemElem.type === "object" ? {} : itemElem.type === "array" ? [] : undefined);
				args.update({ value: [...(args.value || []), newElem] });
				setActive((args.value || []).length);
			};


			if (boxType === "table") {
				const jsxTable = renderArrayTable(
									arrayRows, canAppend && addHandle,
									args, itemElem, this.self.objects.control, this.self.objects, this.props, this.self.lib);

				const arrayObj = this.embedObject(args, jsxTable, { isContainer: true });
				arrayObj && pushElem(arrayObj, args);

			} else if (boxType !== "card") {

				// Wrap array in container and then in object embed
				const arrayContainer = this.embedArrayContainer(args, itemElem, arrayElems, canAppend && addHandle, boxType);
				const arrayObj = this.embedObject(args, arrayContainer, { isContainer: true });
				arrayObj && pushElem(arrayObj, args);
			}


		}




		const parseObject = (keys: string[], rootPath: string,
							 baseJsonSchema: IJsonSchemaObject,
							 layoutOptions: IUiSchemaPanelLayoutOptions,
							 pushElem: (elem: ReactNode, args: IUiSchemaElemArgs) => void) => {

            for (const keyGrp of keys) {

				const pathArr = keyGrp.replace(/[.]/g, "/").split("/");			// for the MODEL we convert . to /
				const key     = pathArr.pop() as string;
				const path    = pathArr.join("/");
				let   keypath = rootPath + (rootPath && path ? "/" : "") + path;
				let   fullkey = (keypath ? keypath + "/" : "") + key;
				const values  = valuesProxy[keypath + "?"];
				const errors  = errorsProxy[keypath + "?"];

				// now resolve the schema object
				const sPathArr   = keyGrp.split("/");			// For the SCHEMA we keep the . separated elements in the key
				const sKey       = sPathArr.pop() as string;
				let   jsonSchema = baseJsonSchema;

				for (const pKey of sPathArr) {
					jsonSchema = (jsonSchema?.properties || {})[pKey] || {};
				}
				const properties = jsonSchema.properties || {};
                let   elem       = properties[sKey];

				if (elem == null) { continue; }
				if (elem.$ref) { elem = getRef(elem, rootCondJsonSchema, true); }

				const uiElem   = (elem || {}).$uiSchemaObject || {};
				const required = !!(jsonSchema.required && jsonSchema.required.includes(key));
				const args     = getArgs(jsonSchema, elem, uiElem, layoutOptions, keypath, key, values, errors, required);
				if (!args) { continue; }

				// Process the object. If it is an ARRAY we will loop over all the elements. Further if it is an array of Objects
				// we will process the object properties directly.

				if (args.type === "array") {

					parseArray(args, layoutOptions, pushElem);

				} else if (args.type === "object") {

					const objectJsxElemObjects: ReactNode[] = [];
					const objectColElems: IElemNode[] = [];
					const objectLayoutOptions = { ...layoutOptions, ...args.uiElem.layoutOptions };
					const objectPushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => 
													objectColElems.push({ jsxElem, args });

					const orderedKeys = this.getOrderedKeys(Object.keys(elem.properties || {}), elem.properties);
					parseObject(orderedKeys,	// keys of object
						fullkey,				// absolute path of the object
						elem,					// schema
						{ ...objectLayoutOptions, ...args.uiElem.nestedLayoutOptions },
						objectPushElem);
					
						schemaElemColLayout(objectColElems, objectJsxElemObjects as JSX.Element[], objectLayoutOptions.numColumns || 0);
						const embeddedObj = this.embedObject(args, <>{objectJsxElemObjects}</>, { isContainer: true });

 						pushElem(embeddedObj, args);

				} else {

					// The object is NOT an array, so we just invoke directly the component handler.

					if (args && args.type && this.componentHandlers[args.type]) {
						const obj = this.componentHandlers[args.type](args);

						if (obj && !(obj as JSX.Element).key) { console.log("Missing key in object", args); }

						obj && pushElem(obj, args);
					}
				}
			}
		}



		// getArgs parse an element and generate the IUiSchemaElemArgs structure that is passed to all component
		// functions

		const getArgs = (jsonSchema: IJsonSchemaObject, elem: IJsonSchemaObject, uiElem: IUiSchemaObject, layoutOptions: IUiSchemaPanelLayoutOptions,
						   keypath: string, key: string | number,
						   values: any, errors: any, required: boolean) => {

			const fullkey = (keypath ? keypath + "/" : "") + key;
			const error   = errors[key];
			const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, uiSchema, oldValues, control };

			let value = values[key];
			if (value === undefined && !this.innerStates[fullkey]?.modified) { value = elem.default };

			// Resolve readOnly states
			const evReadOnly = typeof uiElem.readOnly === "boolean" ? typeof uiElem.readOnly
								: typeof uiElem.readOnly === "string"
									? evalExpr(uiElem.readOnly, lib, objects, { fullkey, value, error, readOnly: null, schema: elem }, false) : null;
			const elemReadOnly = typeof evReadOnly === "boolean" ? evReadOnly :
								(typeof elem.readOnly === "boolean" ? elem.readOnly : (control.modalReadOnly || false));
			const readOnly = typeof evReadOnly === "boolean" ? evReadOnly : (elemReadOnly || control.readOnly);

			// Check if this element should be hidden
			if (uiElem.hidden === true || (typeof uiElem.hidden === "string" && 
					evalExpr(uiElem.hidden, lib, objects, { fullkey, value, error, readOnly, schema: elem }, false))) {
				return null;
			}

			if (uiElem.getValue) {
				try {
					value = evalExpr(uiElem.getValue, lib, objects, { fullkey, value, error, readOnly, schema: elem }, Error);
				} catch (e: any) {
					self.log("error in custom getValue function for " + key + ":" + e.message);
				}
			}

			const enumElem = uiElem.getEnum
								? evalExpr(uiElem.getEnum, lib, objects, { fullkey, value: uiElem.enum || elem.enum, readOnly, schema: elem }, [])
								: (uiElem.enum || elem.enum);
			const labels: any = {};
			if (enumElem) {
				const enumLabels = uiElem.getEnumLabels
									? evalExpr(uiElem.getEnumLabels, lib, objects, { fullkey, value: uiElem.enumLabels, readOnly, schema: elem }, [])
									: uiElem.enumLabels;

				if (enumLabels) {
					for (const uie of enumLabels) {
						labels[uie.value + ""] = uie.label;
					}
				}
			}


			// Process titles and descriptions
			const titleLayout = uiElem?.titleLayout       || layoutOptions.titleLayout;
			const descLayout  = uiElem?.descriptionLayout || layoutOptions.descriptionLayout;
			const description = evalString((lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
			const title       = evalString((lang && (elem as any)["title[" + lang + "]"]) || elem.title || key,
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
			const helpLink = uiElem.helpLink ? evalString(uiElem.helpLink, lib, objects, 
								{ fullkey, value, error, readOnly, schema: elem }) + "" : null;


			const type = uiElem?.type || (elem.type && enumElem ? "select" : (Array.isArray(elem.type) ? elem.type[0] : elem.type)) || "";

			if (uiElem?.placeholder) {
				uiElem = {
					...uiElem,
					placeholder: evalString((lang && (uiElem as any)["placeholder[" + lang + "]"]) || uiElem.placeholder,
											lib, objects, { fullkey, value, error, readOnly, schema: elem }) + ""
				};
			}

			const elementArgs: IUiSchemaElemArgs = {
				key: key as string, 			// TODO:
				fullkey,
				elem,
				layoutOptions,
				uiElem,
				// The value parsed is normally the value, but can be also the title or description text.
				value: titleLayout === "value" ? title : descLayout === "value" ? description : value,
				values,
				title,
				description,
				helpLink,
				readOnly,
				elemReadOnly,
				required,
				error,
				errors,
				dropFile: uiElem.dropFile,
				update: (update: IValueUpdate) => {
					const ts0 = Date.now();
					let targetObj: IUiSchemaSetValueResult = { value: update.value };
					if (uiElem.setValue) {
						try {
							targetObj = evalExpr(uiElem.setValue, lib, objects, { fullkey, value: update.value, error, readOnly, schema: elem }, Error);
							self.log("setValue", targetObj, update.value);
						} catch (e: any) {
							self.log("error in custom setValue function for " + key + ":" + e.message);
						}
					}

					if (targetObj) { this.updateValues(targetObj, keypath, key + ""); }
					this.logTime("update", Date.now() - ts0);
				},
				enumLabels: labels,
				enums: enumElem as any,
				type,

				objects,
				lib,
				embedObject: (obj, flex) => this.embedObject(elementArgs, obj, flex),
				getSettings: this.props.getSettings,
				stringToComponent: (text: string) => {
					const txt = evalString(text, lib, objects, { fullkey, value, error, readOnly, schema: elem }) + "";
					const jsx = this.formatTextArgs(txt, elementArgs);
					return jsx;
				}
			};

			// If there is a value to be auto set, we schedule it to be updated after the render is completed.
			if (uiElem.autoSet && enumElem?.length > 0 && !enumElem.includes(value)) {
				this.deferredUpdate.push(() => elementArgs.update({ value: enumElem[0] }));
			}

			if (!type || (!this.componentHandlers[type] && type !== "array" && type !== "object")) {
				self.log("Unknown type " + type + " for " + fullkey);
			}
			if (error) { hasError = true; }


			return elementArgs;
		};



		// Implement the column layout function that is passed to the parseObject function and is used to
		// layout the added fields in columns, depending on layoutOptions.
		const elemNodes: IElemNode[] = [];
		const pushElem = (jsxElem: ReactNode, args: IUiSchemaElemArgs) => elemNodes.push({ jsxElem, args });

		// Start scanning the object via the supplied key list.
		//
        if (rootCondJsonSchema && rootCondJsonSchema.type === "object" && rootCondJsonSchema.properties && keys) {
			parseObject(keys, "", rootCondJsonSchema, layoutOptions, pushElem);
		}

		// Layout the interface
			schemaElemColLayout(elemNodes, jsxElems as JSX.Element[], layoutOptions.numColumns || 0);

        return { hasError, cards };
    }



    public render(): ReactNode {
		// To be overridden by extended class
		return <div></div>;
    }
}



export function flatCardList(cardsMap: {}, cards: CardListElem[]) {
	for (const card of cards) {
		if (Array.isArray(card)) {
			flatCardList(cardsMap, card);
		} else if (typeof card === "string") {
			cardsMap[card] = 1;
		}
	}
}
