import React from 'react';
import { OverlayTrigger, Tooltip} from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const RenderTagsOverlay  = ( input, color = "blue", link = null, state = null, tagTitle="", maxWidth='130px') => {
    if (!input) return '';

    var inputArr = input.split(",");
    
    const popover = (
        <Popover id="popover-basic" className="ow-popover">
          <Popover.Header as="h3" className="mt-0">{tagTitle}</Popover.Header>
            <Popover.Body className="p-2">
                
                    {
                    inputArr.map(( tag, index ) => {
                    if(link) {
                        if(state) {
                            if(state.row.hasOwnProperty('groupid')) {
                                state.row.groupid = tag;
                            } else if(state.row.hasOwnProperty('accountid')) {
                                state.row.accountid = tag;
                            } else if(state.row.hasOwnProperty('tagid')) {
                                state.row.tagid = tag;
                            }
                            
                        }
                        
                        let url =  link.replace(/~tag~/g, tag);
                            return (<span style={{ "maxWidth": "10rem"}} className={`badge ${color} ${url}`} key={index}><Link state={state}  className="no-link" to={url}>{tag}</Link></span>)
                    } else {
                            return (<span style={{ "maxWidth": "10rem"}} className={`badge ${color}`} key={index}>{tag}</span>)
                    }
                })} 
            </Popover.Body>
        </Popover>
      );
    
      const renderTitleTooltip = ( toolTip ) => {
        return (
            <Tooltip >
                {toolTip}
            </Tooltip>
        )
    }

    const TagsOverlay = () => {
        return (
            <OverlayTrigger 
                trigger={['click']}
                placement={isMobile ? "top" : "right"}
                overlay={popover} rootClose>
                <small className='ms-2'><i className="fa-regular fa-message-dots"></i></small>
            </OverlayTrigger>
        )
    }
    const RenderTag = ( { inputArr } ) => {
        const arrLength = inputArr.length;

        let tags = <TagsOverlay/>
        if(arrLength == 1) {
            tags = inputArr.map(( tag, index ) => {
                if(link) {
                    if(state) {
                        if(state.row.hasOwnProperty('groupid')) {
                            state.row.groupid = tag;
                        } else if(state.row.hasOwnProperty('accountid')) {
                            state.row.accountid = tag;
                        } else if (state.row.hasOwnProperty('tagid')) {
                            state.row.tagid = tag;
                        }
                        
                    }
                    
                    let url =  link.replace(/~tag~/g, tag);
                        return (<OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTitleTooltip(tag)}
                            key={index}
                        >
                            <span style={{ "maxWidth": maxWidth}} className={`badge ${color} ow-datatable-overflow-ellipsis ${url}`} key={index}><Link state={state}  className="no-link ow-datatable-overflow-ellipsis" to={url}>{tag}</Link></span>
                            </OverlayTrigger>)
                } else {
                        return (<OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTitleTooltip(tag)}
                        ><span style={{ "maxWidth": maxWidth}} className={`badge ${color} ow-datatable-overflow-ellipsis`} key={index}>{tag}</span>
                        </OverlayTrigger>)
                }
            })
        } else {
            let url =  link.replace(/~tag~/g, inputArr[0]);
            tags = <>
            <span style={{ "maxWidth": maxWidth}} className={`badge ${color} ow-datatable-overflow-ellipsis`}><Link state={state}  className="no-link ow-datatable-overflow-ellipsis" to={url}>{inputArr[0]}</Link></span>
            <TagsOverlay />
        </>
        }
        
        return (<>{tags}</>);
    }
   
    return <RenderTag inputArr={inputArr} />;
}

export default RenderTagsOverlay;
  