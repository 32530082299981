import React, { useEffect, useContext } from "react";
import { strings } from "./../../services/Localization";
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import _ from 'lodash';
import { getAppBase } from "../../utils/consts";
import { DataTable, DataTableWrapper, PageHeader, SpinnerHide, SpinnerShow, } from '../../components/Common';
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { BulkActionType } from '../../components/Common/DataTable/DataTypes';
import { DEFAULT_RECORD_LIMIT } from "../../components/Common/DataTable/DataTableConsts";
import {
    getDassDeviceProfilesService,
    getDassServiceProfilesService,
    // getDeviceProfilesService
} from "../../services/batch"
import { toast } from "../../utils/Toaster";

import AppContext from "../../context/AppContext";
import { faCloudArrowDown, faCloudArrowUp, faFileImport, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { createRoot } from "react-dom/client";
import { SchemaModal } from "../../components/SchemaModal/SchemaModal";
import { useNavigate } from "react-router";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { DeviceImportHelper } from "./DeviceImportHelper";
import { FileDropZone } from "./FileUploadZone";

interface IPageState {
    user: any;
    showDeviceList: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    nameIndex: number;
    allSelected: boolean;
    limit: number;
    page: number;
    totalPage: number;
}

const DeviceImport = () => {
    const navigate = useNavigate();
    const appContextObj = useContext(AppContext);
    const loggedInUserId = appContextObj?.user?.userid || "";

    const [showOrbiWANInstanceDropDown, setShowOrbiWANInstanceDropDown] = React.useState(false);

    const orbiwan_instance = React.useRef(null);
    const typeOfDevicesBeingImported = React.useRef(null); // dass, file-upload-provisioned, file-upload-dass
    const deviceProfiles = React.useRef([]);
    const connections = React.useRef([]);
    const dataTableOptions = React.useRef(null);
    const allDevices = React.useRef([]);
    const allDevicesBroughtFromDASS = React.useRef(false);

    // const [orbiwanInstances, setOrbiwanInstances] = React.useState([]);

    const [showDataTable, setShowDataTable] = React.useState(false);

    const [showDeviceProfileDropDown, setShowDeviceProfileDropDown] = React.useState(false);
    const [showConnectionsDropDown, setShowConnectionsDropDown] = React.useState(false);

    const [selectedDevices, setSelectedDevices] = React.useState([]);
    const [showFileImportBox, setShowFileImportBox] = React.useState(false);

    const [dassServiceProfiles, setDassServiceProfiles] = React.useState([]);
    const [dassDeviceProfiles, setDassDeviceProfiles] = React.useState([]);

    const orbiwanInstances = appContextObj?.user ? appContextObj.user['orbiwan_creds'] ? appContextObj.user['orbiwan_creds'] : [] : [];

    const PageStateInit: IPageState = {
        user: {},
        pageTitle: '',
        showDeviceList: false,
        allSelected: true,
        limit: 50,
        page: 0,
        totalPage: 0,
        breadCrumbArr: [
            { label: strings.NAV_DEVICES_ITEM, url: `${getAppBase()}/dmp-devices` },
            { label: strings.DEVICE_IMPORT, url: '' }
        ],
        nameIndex: 0,
    }


    const selectOrbiWANInstanceHandler = (instanceName) => {
        console.log(`selected orbiwan instance`, instanceName);
        orbiwan_instance.current = instanceName;
        console.log(`typeOfDevicesBeingImported in selectOrbiWANInstanceHandler`, typeOfDevicesBeingImported.current, ` and setting it to - dass`);
        typeOfDevicesBeingImported.current = 'dass';
        allDevices.current = [];

        setShowOrbiWANInstanceDropDown(false);
    }

    const getDassBulkActions = () => {

        let dassBulkActions: BulkActionType[] = [
            // set Device Profile
            {
                type: "action",
                text: strings.SET_DEVICE_PROFILE,
                icon: faFileImport,
                action: (data) => {
                    setShowDeviceProfileDropDown(true);
                    setSelectedDevices(data);
                },
            },
            {
                type: "action",
                text: strings.SET_CONNECTION,
                icon: faFileImport,
                action: (data) => {
                    setShowConnectionsDropDown(true);
                    setSelectedDevices(data);
                },
                visible: () => typeOfDevicesBeingImported.current === 'file-upload-provisioned'
            }
        ];

        return dassBulkActions;
    }

    const registerDevices = async () => {
        console.log(`registerDevices`, typeOfDevicesBeingImported.current);

        // get devices which have a dmp_device_profile_uuid set
        const devicesToRegister = [];
        const tableData = dataTableOptions.current.data;
        let devicesAlreadyInDMP = 0;
        for (let i = 0; i < tableData.length; i++) {
            const row = tableData[i];
            if (typeOfDevicesBeingImported.current === 'file-upload-provisioned') {
                if (row.dmp_device_profile_uuid && !row.already_in_dmp && row.deviceUuid && row.provisionToken) {
                    devicesToRegister.push(row);
                }
            } else if (row.dmp_device_profile_uuid && !row.already_in_dmp && row.device_profile_uuid && row.service_profile_uuid) {
                devicesToRegister.push(row);
            }
            if (row.already_in_dmp) {
                devicesAlreadyInDMP++;
            }
        }

        if (devicesAlreadyInDMP > 0 && tableData.length === devicesAlreadyInDMP) {
            toast.success(strings.ALL_DEVICES_REGISTERED_ALREADY);
            return;
        }

        console.log(`devicesToRegister`, devicesToRegister);
        let paramToCheck = typeOfDevicesBeingImported.current === 'file-upload-provisioned' ? 'deviceUuid' : 'deveui';
        if (devicesToRegister.length > 0) {
            // call the service to register the devices
            // /rest/devices
            for (let i = 0; i < devicesToRegister.length; i++) {
                const device = devicesToRegister[i];
                const deviceObj = {
                    "description": "",
                    "device_profile_uuid": device.dmp_device_profile_uuid,
                    "notes": "", "parameters": {},
                    "tags_lns": {},
                }

                if (typeOfDevicesBeingImported.current === 'file-upload-provisioned') {
                    deviceObj['provisionToken'] = device.provisionToken;
                    deviceObj['deviceUuid'] = device.deviceUuid;
                    deviceObj['deviceId'] = '';
                    deviceObj['name'] = 'Imported';
                    if(device.dmp_connection_uuid) {
                        deviceObj['connection_uuid'] = device.dmp_connection_uuid;
                    }
                } else {
                    // add service and device profiles, name
                    deviceObj['name'] = device.comment || 'Imported - ' + device.deveui;
                    deviceObj['deviceId'] = device.deveui;
                    deviceObj['dass_device_profile_uuid'] = device.device_profile_uuid;
                    deviceObj['dass_service_profile_uuid'] = device.service_profile_uuid;
                    deviceObj['parameters'] = {
                        "device_class": device.lora_device_class,
                        "appkey": device.appkey
                    }
                    deviceObj['tags_lns'] = device.tags;
                    deviceObj['applications_lns'] = device.applications || '';
                }

                deviceObj['imported_device'] = true;

                const res = await DeviceImportHelper.saveDevice(deviceObj);
                console.log(`deviceObj`, deviceObj, res);
                let percent = ((i + 1) * 100 / devicesToRegister.length).toFixed(1);
                showProgress(percent, `Registering Device ${i + 1} / ${devicesToRegister.length} - ${device[paramToCheck]}`);
            }
            setTimeout(() => {
                toast.success(devicesToRegister.length + ' ' + strings.DEVICES_REGISTERED);
                hideProgress();
                if (typeOfDevicesBeingImported.current !== 'dass') {
                    buildDataTable();
                } else {
                    allDevicesBroughtFromDASS.current = false;
                    bringDevicesFromDASS();
                }
            }, 2000);

        } else {
            // if(devicesAlreadyInDMP > 0) {
            //     toast.success(strings.ALL_DEVICES_REGISTERED_ALREADY);
            // } else{
            toast.error(strings.NO_DEVICE_TO_REGISTER);
            // }
        }

    }

    let DataTableContextObj = useContext(DataTableContext);

    const restartAgainHandler = () => {
        console.log(`restartAgainHandler`);
        dataTableOptions.current = null;
        DataTableContextObj.setSearchState(prev => {
            prev.dataTableOption = {};
            return prev;
        });
        // clear all the data
        orbiwan_instance.current = null;
        typeOfDevicesBeingImported.current = null;
        allDevices.current = [];
        // deviceProfiles.current = [];
        // setOrbiwanInstances([]);
        setShowFileImportBox(false);
        setShowOrbiWANInstanceDropDown(false);
        setDassDeviceProfiles([]);
        setDassServiceProfiles([]);
        setShowDataTable(false);
        setSelectedDevices([]);
        setShowDeviceProfileDropDown(false);
        setShowFileImportBox(false);
    }

    const processDassDevices = async (devices) => {
        console.log(`Processing OrbiWAN devices`);
        // the devices brought from OrbiWAN may already be in the system
        // extract all deveuis 
        const devicesMap = {};
        const deveuis = devices.map((item) => {
            devicesMap[item.deveui] = item;
            return item.deveui;
        });

        // lets get all of the devices from the system
        const res = await DeviceImportHelper.searchDevicesWithPagination();
        if (res && !res.error) {
            const devicesList = res.data;
            const deveuisInSystem = devicesList.map((item) => item.deviceId);
            for (let i = 0; i < deveuis.length; i++) {
                const deveui = deveuis[i];
                if (deveuisInSystem.includes(deveui)) {
                    devicesMap[deveui].already_in_dmp = true;
                    devicesMap[deveui].dmp_device_profile_uuid = devicesList[deveuisInSystem.indexOf(deveui)].device_profile_uuid;
                }
            }
        }
    }

    const processDevicesForProfiles = async (devices) => {
        console.log(`Processing OrbiWAN devices for profiles`);
        // for each device, update the device profile and service profile
        const dassDeviceProfilesMap = {};
        const dassServiceProfilesMap = {};
        for (let i = 0; i < dassDeviceProfiles.length; i++) {
            const profile = dassDeviceProfiles[i];
            dassDeviceProfilesMap[profile.profile_uuid] = profile;
        }
        for (let i = 0; i < dassServiceProfiles.length; i++) {
            const profile = dassServiceProfiles[i];
            dassServiceProfilesMap[profile.profile_uuid] = profile;
        }
        const deviceProfileMap = {};
        for (let i = 0; i < deviceProfiles.current.length; i++) {
            const profile = deviceProfiles.current[i];
            deviceProfileMap[profile.profile_uuid] = profile;
        }
        const connectionsMap = {};
        for (let i = 0; i < connections.current.length; i++) {
            const connection = connections.current[i];
            connectionsMap[connection.connection_uuid] = connection;
        }

        for (let i = 0; i < devices.length; i++) {
            const device = devices[i];
            const device_profile_uuid = device.device_profile_uuid || '';
            const service_profile_uuid = device.service_profile_uuid || '';
            if (device_profile_uuid) {
                device.dass_device_profile_name = dassDeviceProfilesMap[device_profile_uuid]?.profile_name || '';
            } else {
                device.dass_device_profile_name = '';
            }
            if (service_profile_uuid) {
                device.dass_service_profile_name = dassServiceProfilesMap[service_profile_uuid]?.profile_name || '';
            } else {
                device.dass_service_profile_name = '';
            }
            if (device.dmp_device_profile_uuid) {
                device.dmp_device_profile_name = deviceProfileMap[device.dmp_device_profile_uuid]?.profile_name || '';
            } else {
                device.dmp_device_profile_name = 'Not Set';
            }
            if(device.dmp_connection_uuid) {
                device.dmp_connection_name = connectionsMap[device.dmp_connection_uuid]?.profile_name || '';
            } else {
                device.dmp_connection_name = 'Not Set';
            }
        }
    }

    const processProvisionedDevices = async (devices, areProvisionedDevices) => {
        console.log(`Processing Provisioned devices`);
        const deviceProfileMap = {};
        for (let i = 0; i < deviceProfiles.current.length; i++) {
            const profile = deviceProfiles.current[i];
            deviceProfileMap[profile.profile_uuid] = profile;
        }
        const connectionsMap = {};
        for (let i = 0; i < connections.current.length; i++) {
            const connection = connections.current[i];
            connectionsMap[connection.connection_uuid] = connection;
        }

        // the devices brought from OrbiWAN may already be in the system
        const parameterToCheck = areProvisionedDevices ? 'deviceUuid' : 'deveui';
        console.log(`Checking for parameterToCheck`, parameterToCheck, typeOfDevicesBeingImported.current, devices.map((item) => item[parameterToCheck]));
        // extract all deveuis 
        const devicesMap = {};
        const deviceUuids = devices.map((item) => {
            devicesMap[item[parameterToCheck]] = item;
            return item[parameterToCheck];
        });

        // lets get all of the devices from the system
        const res = await DeviceImportHelper.searchDevicesWithPagination();
        if (res && !res.error) {
            const devicesList = res.data;
            const devicesInSystem = devicesList.map((item) => item.deviceUuid);
            for (let i = 0; i < deviceUuids.length; i++) {
                const deviceUuid = deviceUuids[i];
                console.log(`Checking for deviceUuid`, deviceUuid, devicesInSystem.includes(deviceUuid));
                devicesMap[deviceUuid].already_in_dmp = false;
                if (devicesInSystem.includes(deviceUuid)) {
                    devicesMap[deviceUuid].already_in_dmp = true;
                    devicesMap[deviceUuid].dmp_device_profile_uuid = devicesList[devicesInSystem.indexOf(deviceUuid)].device_profile_uuid;
                    devicesMap[deviceUuid].dmp_device_profile_name = deviceProfileMap[devicesList[devicesInSystem.indexOf(deviceUuid)].device_profile_uuid]?.profile_name || '';

                    // connections
                    devicesMap[deviceUuid].dmp_connection_uuid = devicesList[devicesInSystem.indexOf(deviceUuid)].connection_uuid;
                    devicesMap[deviceUuid].dmp_connection_name = connectionsMap[devicesList[devicesInSystem.indexOf(deviceUuid)].connection_uuid]?.profile_name || '';
                }
            }
        }
    }


    const buildDataTable = async () => {
        let areProvisionedDevices = typeOfDevicesBeingImported.current === 'file-upload-provisioned';
        if (!areProvisionedDevices) {
            // check if the devices are already in the system
            await processDassDevices(allDevices.current);
            await processDevicesForProfiles(allDevices.current);
        } else {
            await processProvisionedDevices(allDevices.current, areProvisionedDevices);
            await processDevicesForProfiles(allDevices.current);
        }
        console.log(`DataAfterProcessing`, allDevices.current);

        dataTableOptions.current = {
            columns: areProvisionedDevices ? DeviceImportHelper.provisionedDevicesColumns : DeviceImportHelper.dassDeviceColumns,
            data: allDevices.current,
            actions: [],
            bulkActions: getDassBulkActions(),
            available_key: areProvisionedDevices ? 'deviceUuid' : 'deveui',
            id_field: areProvisionedDevices ? 'deviceUuid' : 'deveui',
            enableDebug: true,
            dataType: 'memory',
            allCheckboxSelected: true,
            allowBulkActions: true,
            serial_number: false,
            url: 'default-url',
            modal: false,
            emptyDataMsg: `<b>${strings.SORRY}!</b> ${strings.NO_DEVICE_AVAILABLE}`,
            query_param: { all: true, get_pages: true, limit: DEFAULT_RECORD_LIMIT, stream: 'memory' } as any,
            selectedDevices: selectedDevices,
        }
        setShowDataTable(true);
        setPageState(prevState => {
            const nameIndex = prevState.nameIndex + 1;
            return { ...prevState, nameIndex: nameIndex }
        });
    };


    const updateDevicesData = ({
        device_profile_uuid = undefined,
        connection_uuid = undefined
    }) => {
        console.log(`updateDevicesData with device_profile_uuid: ${device_profile_uuid}, typeOfDevicesBeingImported.current ${typeOfDevicesBeingImported.current}`);
        const paramToCheck = typeOfDevicesBeingImported.current === 'dass' || typeOfDevicesBeingImported.current === 'file-upload-dass' ? 'deveui' : 'deviceUuid';
        const selectedDevicesForBulk = selectedDevices.map((item) => item[paramToCheck]);
        console.log(`selectedDevicesForBulk`, selectedDevicesForBulk);
        for (let i = 0; i < allDevices.current.length; i++) {
            if (selectedDevicesForBulk.includes(allDevices.current[i][paramToCheck])) {
                if (device_profile_uuid) {
                    allDevices.current[i].dmp_device_profile_uuid = device_profile_uuid;
                }
                if (connection_uuid) {
                    allDevices.current[i].dmp_connection_uuid = connection_uuid;
                }
            }
        }
        buildDataTable();
    }

    const getDeviceProfiles = async () => {
        // call the service to get the device profiles
        // /rest/device-profiles
        // const res = await getDeviceProfilesService();
        // const validProfiles = [];
        // for (let i = 0; i < res.length; i++) {
        //     const profileContent = typeof res[i].profileContent === 'string' ? JSON.parse(res[i].profileContent) : res[i].profileContent;
        //     res[i].profileContent = profileContent;
        //     validProfiles.push(res[i]);
        // }
        // deviceProfiles.current = validProfiles;

        const res = await DeviceImportHelper.getDeviceProfilesWithPagination();
        if (!res.error) {
            deviceProfiles.current = res.data;
        }
    }

    const getConnections = async () => {
        // call the service to get the device profiles
        // /rest/device-profiles
        const res = await DeviceImportHelper.getConnectionsWithPagination();
        if (!res.error) {
            connections.current = res.data;
        }
    }

    const bringDevicesFromDASS = async () => {
        if (!allDevicesBroughtFromDASS.current) {
            SpinnerShow();
            try {
                const data = await DeviceImportHelper.getDevicesFromDass(orbiwan_instance.current);
                allDevices.current = data.rows;
                allDevicesBroughtFromDASS.current = true;
                await buildDataTable();
            } catch (error) {
                console.log(`Error getting devices from DASS`, error);
                toast.error(`${error.message}`);
                orbiwan_instance.current = null;
                setDassDeviceProfiles([]);
                setDassServiceProfiles([]);
            }
            SpinnerHide();
        }
    };

    const bringProfilesFromDASS = async () => {
        // get the device profiles and service profiles from OrbiWAN
        try {
            const res = await getDassDeviceProfilesService(orbiwan_instance.current);
            console.log(res);
            if (res.status !== 200) {
                toast.error(`${res['message']}`);
                return;
            }
            setDassDeviceProfiles(res.data);
        } catch (error) {
            console.log(`Error getting device profiles`, error);
            toast.error(`${error.message}`);
        }

        try {
            const res = await getDassServiceProfilesService(orbiwan_instance.current);
            if (res.status !== 200) {
                toast.error(`${res['message']}`);
                return;
            }
            const serviceProfiles = res.data
            setDassServiceProfiles(serviceProfiles);
        } catch (error) {
            console.log(`Error getting service profiles`, error);
            toast.error(`${error.message}`);
        }
    }

    const showOrbiWANInstance = () => {
        console.log(`Show OrbiWAN Instance or select from the available instances`);
        // show a dropdown with the available OrbiWAN instances
        const user = appContextObj?.user || null;
        allDevicesBroughtFromDASS.current = false;
        if (user && user['orbiwan_creds']) {
            const orbiwan_creds = user['orbiwan_creds'];
            if (orbiwan_creds.length > 1) {
                console.log(`OrbiWAN instances are more than 1`);
                // show modal with dropdown with list of orbiwan instances
                setShowOrbiWANInstanceDropDown(true);
                // setOrbiwanInstances(orbiwan_creds);
                typeOfDevicesBeingImported.current = 'dass';
            } else if (orbiwan_creds.length === 1) {
                console.log(`OrbiWAN instances are 1 - ${orbiwan_creds[0].instance_name}`);
                // bring devices from dass
                // setOrbiwanInstances(orbiwan_creds);
                orbiwan_instance.current = orbiwan_creds[0].instance_name;
                typeOfDevicesBeingImported.current = 'dass';
                allDevices.current = [];
            } else {
                toast.error(strings.NO_ORBIWAN_INSTANCE);
                orbiwan_instance.current = null;
            }
        } else {
            toast.error(strings.NO_ORBIWAN_INSTANCE);
            orbiwan_instance.current = null;
        }
    }

    useEffect(() => {
        console.log(`orbiwan_instance.current`, orbiwan_instance.current);
        if (orbiwan_instance.current) {
            if (dassDeviceProfiles.length === 0 && dassServiceProfiles.length === 0) {
                bringProfilesFromDASS();
            } else {
                bringDevicesFromDASS();
            }
        }
    }, [orbiwan_instance.current, dassDeviceProfiles, dassServiceProfiles])

    // get the current user from the appContext and trigger the reload of the page.
    useEffect(() => {

        setPageState(prevState => {
            return { ...prevState, user: appContextObj?.user }
        });

        if (!deviceProfiles.current || deviceProfiles.current.length === 0) {
            console.log(`Getting device profiles`);
            getDeviceProfiles();
        }

        if (!connections.current || connections.current.length === 0) {
            console.log(`Getting connections`);
            getConnections();
        }

    }, []);
    // ------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------------------------



    const [pageState, setPageState] = React.useState<IPageState>(PageStateInit);

    const getPageButtons = () => {

        const pageButtons: PageButtonType[] = [
            // refresh table
            {
                title: strings.RESTART,
                action: () => { restartAgainHandler() },
                type: 'button_with_icon',
                icon: faRefresh,
                visible: () => true
            },

            // import from dass
            {
                title: strings.IMPORT_FROM_DASS,
                action: () => { showOrbiWANInstance() },
                type: 'button_with_icon',
                icon: faCloudArrowDown,
                visible: () => !showDataTable
            },

            // Upload file
            {
                title: strings.UPLOAD_FILE,
                action: () => {
                    dataTableOptions.current = null;
                    orbiwan_instance.current = null;
                    allDevices.current = [];
                    dataTableOptions.current = null;

                    DataTableContextObj.setSearchState(prev => {
                        prev.dataTableOption = {};
                        return prev;
                    });
                    setDassDeviceProfiles([]);
                    setDassServiceProfiles([]);
                    setShowFileImportBox(true);
                },
                type: 'button_with_icon',
                icon: faCloudArrowUp,
                visible: () => !showDataTable
            },
            // register devices
            {
                title: strings.REGISTER,
                action: () => { registerDevices() },
                type: 'button_with_icon',
                icon: faFileImport,
                visible: () => showDataTable
            },
        ]

        return pageButtons;
    };


    const showProgress = (complete, caption = 'uploading') => {
        var element = document.getElementById("progress-bar");
        if (element) {
            element.classList.remove("d-none");
            const rootInstance = createRoot(document.getElementById("progress-bar"))
            rootInstance.render(<ProgressBarComponent value={complete} caption={`${caption}`} />)
        }
    }

    const hideProgress = () => {
        var element = document.getElementById("progress-bar");
        if (element) {
            element.classList.add("d-none");
        }
    };

    const uploadFile = (file) => {
        console.log(`File uploaded`, file);
        // set orbitwan_instance to null
        orbiwan_instance.current = null;
        allDevices.current = [];
        // setOrbiwanInstances([]);

        const reader = new FileReader();
        reader.onload = (event) => {
            try {
                const allowedFormats = ['csv', 'txt']; // Allowed file formats
                const fileExtension = file.name.split('.').pop().toLowerCase();

                if (!allowedFormats.includes(fileExtension)) {
                    toast.error('Only CSV file format (.csv) are allowed.');
                    return;
                }
                // process the file
                processUploadedFile(event.target.result);
                // build the data table
                buildDataTable();
            } catch (error) {
                console.log(`Error processing file`, error);
                toast.error(strings.ERROR_PROCESSING_FILE);
            }
        };
        reader.readAsText(file);
    };

    const processUploadedFile = (fileContent) => {

        const data = {
            rows: [],
            columnsHeader: [],
            // fileTypeBeingImported: null
        };

        // possible columns when devices are pre-registered in dmp
        // deviceUuid, provisionToken, url, bearerToken

        // possible columns when devices are not pre-registered in dmp and are being brought from dass
        // deveui, comment, device_profile_uuid, service_profile_uuid
        const rows = fileContent.split('\n');
        const columnsHeader = [];
        const tempCols = rows[0].split(',');
        // sanitize the columns, because the csv exported from orbiwan has - "deveui","appeui","comment","devaddr","device_status","altitude"..
        for (let i = 0; i < tempCols.length; i++) {
            const col = tempCols[i].trim();
            columnsHeader.push(DeviceImportHelper.sanitizeValue(col));
        }



        // check if the columns have deviceUuid or provisionToken
        let areProvisionedDevices = columnsHeader.includes('deviceUuid') || columnsHeader.includes('provisionToken');
        console.log(`areProvisionedDevices`, areProvisionedDevices, columnsHeader);
        for (let i = 1; i < rows.length; i++) {
            if (rows[i].length === 0) {
                continue;
            }
            const row = rows[i].split(',');
            const rowObj = {};
            for (let j = 0; j < columnsHeader.length; j++) {
                const value = DeviceImportHelper.sanitizeValue(row[j]);
                if (areProvisionedDevices && DeviceImportHelper.provisionedDevicesColumnNames.includes(columnsHeader[j])) {
                    rowObj[columnsHeader[j]] = value || '';
                } else if (!areProvisionedDevices && DeviceImportHelper.dassDevicesColumnNames.includes(columnsHeader[j])) {
                    rowObj[columnsHeader[j]] = value || '';
                }
                if (columnsHeader[j] === 'lora_device_class') {
                    rowObj[columnsHeader[j]] = DeviceImportHelper.lora_classes[value] || '';
                }
            }
            rowObj['appkey'] = '';
            data.rows.push(rowObj);
        }

        allDevices.current = data.rows;

        data.columnsHeader = columnsHeader;
        console.log(`data after upload`, data);
        // data.fileTypeBeingImported = areProvisionedDevices ? 'file-upload-provisioned' : 'file-upload-dass';
        typeOfDevicesBeingImported.current = areProvisionedDevices ? 'file-upload-provisioned' : 'file-upload-dass';
        return data;
    }

    const filteredDeviceProfiles = deviceProfiles.current.filter((item) => {

        if (typeOfDevicesBeingImported.current === 'file-upload-provisioned') {
            if (item.profileContent?.deviceType !== 'orbiwan') {
                return true;
            }
            return false;
        } else {
            if (item.profileContent?.deviceType === 'orbiwan' && item.profileContent?.orbiwanInstance === orbiwan_instance.current) {
                return true;
            }
            return false;
        }
    });

    const deviceProfileDropdownOptions = filteredDeviceProfiles.map((item) => (
        <option key={item.profile_uuid} value={item.profile_uuid}>{item.profile_name}</option>
    ));

    const connectionsDropdownOptions = connections.current.map((item) => (
        <option key={item.profile_uuid} value={item.profile_uuid}>{item.profile_name}</option>
    ));

    return (
        !loggedInUserId ? <div /> :
            <>
                <div id="progress-bar" className="d-none px-2" style={{ height: '1rem' }}></div>
                <DataTableWrapper>
                    <PageHeader name={`device-import-${pageState.nameIndex}`} breadCrumbArr={pageState.breadCrumbArr} pageButtons={getPageButtons()} countLabel={`Devices`} />

                    {
                        showDataTable && dataTableOptions.current &&
                        <>
                            <DataTable
                                key={`device-import-${pageState.nameIndex}`}
                                name={`Device Import From OrbiWAN`}
                                dataTableOption={dataTableOptions.current}
                                display={showDataTable ? 'block' : 'none'}
                                countLabel={`Devices`}
                                refresh={true}
                            />
                        </>
                    }
                    {
                        showFileImportBox && (
                            <div className="mx-0 d-lg-flex border-bottom border-2 mb-2 " >
                                <div className="d-flex w-100 align-items-center justify-content-center p-2" >
                                    <FileDropZone uploadFile={uploadFile} />
                                </div>
                            </div>
                        )
                    }
                    {
                        (!showDataTable && !showFileImportBox && !dataTableOptions.current) && (
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <SchemaModal
                                    SchemaUrl="/rest/devices/get_device_import_help?get_schema=dassui,get"
                                    type="page-fluid"
                                    OnClose={() => { navigate(getAppBase() + '/dmp-devices') }}
                                />
                            </div>
                        )
                    }
                </DataTableWrapper>

                {
                    showOrbiWANInstanceDropDown && (
                        // Modal
                        <Modal show={showOrbiWANInstanceDropDown} onHide={() => setShowOrbiWANInstanceDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>Select OrbiWAN Instance</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <select
                                        onChange={(e) => { selectOrbiWANInstanceHandler(e.target.value) }}
                                    >
                                        <option value="">Select OrbiWAN Instance</option>
                                        {orbiwanInstances.map((item) => {
                                            return <option key={item.instance_name} value={item.instance_name}>{item.instance_name}</option>
                                        })}
                                    </select>
                                </div >
                            </Modal.Body >
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowOrbiWANInstanceDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal >
                    )
                }
                {
                    showDeviceProfileDropDown && (
                        // Modal
                        <Modal show={showDeviceProfileDropDown} onHide={() => setShowDeviceProfileDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{strings.SELECT_DEVICE_PROFILE}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <p>
                                        {
                                            typeOfDevicesBeingImported.current === 'file-upload-provisioned' ? strings.SELECT_DEVICE_PROFILE_NOTE : strings.SELECT_DEVICE_PROFILE_NOTE_LNS
                                        }
                                    </p>
                                    <select
                                        onChange={(e) => {
                                            console.log(`selected device profile`, e.target.value);
                                            // setSelectedOrbiWANInstance(e.target.value);
                                            if (e.target.value !== "") {
                                                setShowDeviceProfileDropDown(false)
                                            }
                                            updateDevicesData({ device_profile_uuid: e.target.value });
                                        }}
                                    >
                                        <option value="">{strings.SELECT_DEVICE_PROFILE}</option>
                                        {
                                            deviceProfileDropdownOptions
                                        }
                                    </select>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowDeviceProfileDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }

                {
                    showConnectionsDropDown && (
                        // Modal
                        <Modal show={showConnectionsDropDown} onHide={() => setShowConnectionsDropDown(false)} centered>
                            <Modal.Header closeButton>
                                <Modal.Title>{strings.SELECT_CONNECTION}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div>
                                    <p>
                                        {strings.SELECT_CONNECTION_NOTE}
                                    </p>
                                    <select
                                        onChange={(e) => {
                                            console.log(`selected connection`, e.target.value);
                                            // setSelectedOrbiWANInstance(e.target.value);
                                            if (e.target.value !== "") {
                                                setShowConnectionsDropDown(false)
                                            }
                                            updateDevicesData({ connection_uuid: e.target.value });
                                        }}
                                    >
                                        <option value="">{strings.SELECT_CONNECTION}</option>
                                        {
                                            connectionsDropdownOptions
                                        }
                                    </select>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowConnectionsDropDown(false)}>{strings.CLOSE}</Button>
                            </Modal.Footer>
                        </Modal>
                    )
                }
            </>
    )
}

export default DeviceImport;


const ProgressBarComponent = (props) => {
    return (<div className="w-100 float-left" >
        <ProgressBar now={props.value} label={`${props.value}% ${props.caption}`} />
    </div>);
}